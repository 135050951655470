import React from "react";
import { Carousel } from "react-responsive-carousel";
import { ToTitleCase } from "../NewsCard/utils/ToTitleCase";
import { ChevronLeft, ChevronRight } from "lucide-react";

// Custom Next Arrow Component
const CustomNextArrow = ({ onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className="absolute z-10 top-0 h-full right-0 bg-zinc-100 bg-opacity-5 dark:bg-black dark:bg-opacity-5 dark:hover:bg-opacity-25 ease-in-out transition-all duration-300 text-zinc-600 hover:bg-opacity-25 dark:text-white p-2 text-lg"
  >
    <ChevronRight size={20} />
  </button>
);

// Custom Prev Arrow Component
const CustomPrevArrow = ({ onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className="absolute z-10 left-0 h-full bg-zinc-100 bg-opacity-5 dark:bg-black dark:bg-opacity-5 dark:hover:bg-opacity-25 ease-in-out transition-all duration-300 text-zinc-600 hover:bg-opacity-25 dark:text-white p-2 text-lg"
  >
    <ChevronLeft size={20} />
  </button>
);

const HeroSection = ({ newsWithimage }) => {
  return (
    <div className="h-full max-w-full xxs:hidden sm:flex text-black dark:text-white">
      <Carousel
        autoPlay
        autoFocus
        infiniteLoop
        interval={3000}
        showThumbs={false}
        showIndicators={false}
        stopOnHover
        swipeable
        emulateTouch
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && <CustomPrevArrow onClick={onClickHandler} />
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && <CustomNextArrow onClick={onClickHandler} />
        }
        showStatus={false}
        width={"100%"}
      >
        {newsWithimage.map((item, index) => (
          <div
            key={index}
            className="overflow-hidden bg-white dark:bg-zinc-900 h-full sm:grid sm:grid-cols-2 sm:items-center p-2 mx-1 justify-center w-full"
          >
            <div className="w-full h-full flex items-center p-6">
              <div className="mx-auto max-w-xl text-center ltr:sm:text-left rtl:sm:text-right flex flex-col bg-zinc-100 shadow-lg dark:shadow-2xl dark:bg-zinc-800 p-6 bg-opacity-50 rounded-lg">
                <span className="news-card-category-body w-fit mb-2">
                  {ToTitleCase(item.type || item.category[0] || "Thinking")}
                </span>
                <h2 className="news-card-title">
                  {item.title ? (
                    new DOMParser()
                      .parseFromString(item.title, "text/html")
                      .body.textContent.split(" ")
                      .slice(0, 20)
                      .join(" ")
                  ) : (
                    <p className="text-xs">NO NEWS TITLE</p>
                  )}
                </h2>

                <p className="hidden news-card-description md:mt-4 md:block">
                  {item.description
                    ? new DOMParser()
                        .parseFromString(item.description, "text/html")
                        .body.textContent.slice(0, 70) + "..."
                    : ""}
                </p>

                <div className="mt-4 md:mt-8">
                  <a
                    href={`/news/${item?._id}`}
                    className="news-card-learn-more-button"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>

            <div className="bg-white dark:bg-zinc-800">
              <img
                alt=""
                src={item.image_source}
                className="h-96 w-52 object-cover rounded-lg"
              />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default HeroSection;
