import React from "react";
import NewsWithImage from "../HomePage/NewsCard/NewsWithImage.js";
import NewsWithoutImage from "../HomePage/NewsCard/NewsWithoutImage.js";

const NewsCardForCategories = ({
  key,
  news,
  onLikeNews,
  onSaveNews,
  showLoginAlert,
  setShowLoginAlert,
  setButtonName,
  buttonName,
}) => {
  return (
    <div className="flex h-full w-full dark:border-zinc-900 rounded-lg hover:shadow-md dark:hover:shadow-md dark:hover:shadow-zinc-700 dark:bg-zinc-800 bg-zinc-100">
      {news.image_source ? (
        <NewsWithImage
          key={key}
          news={news}
          onLikeNews={onLikeNews}
          onSaveNews={onSaveNews}
          showLoginAlert={showLoginAlert}
          setShowLoginAlert={setShowLoginAlert}
          setButtonName={setButtonName}
          buttonName={buttonName}
        />
      ) : (
        <NewsWithoutImage
          key={key}
          news={news}
          onLikeNews={onLikeNews}
          onSaveNews={onSaveNews}
          showLoginAlert={showLoginAlert}
          setShowLoginAlert={setShowLoginAlert}
          setButtonName={setButtonName}
          buttonName={buttonName}
        />
      )}
    </div>
  );
};

export default NewsCardForCategories;
