import CardButtons from "../CardButtons/CardButtons.jsx";
import { ToTitleCase } from "./utils/ToTitleCase.js";
import { useState } from "react";
import sourceImages from "../../../utlis/sourceImages.js";
import { HeartFill } from "@styled-icons/bootstrap/HeartFill";
import { motion } from "framer-motion";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaImg = `${bucketUrl}/backend/assets/Parewa_BLACK_WHITE.png`;

const NewsWithImage = ({
  news,
  setShowLoginAlert,
  setButtonName,
  buttonName,
  toggleSourceName,
}) => {
  const [imageLoaded, setImageLoaded] = useState(true);
  const [showLikePopup, setShowLikePopup] = useState(false);

  const handlePopUpLike = (action) => {
    setShowLikePopup(true);
    setTimeout(() => {
      setShowLikePopup(false);
    }, 1000);
  };

  return (
    <div
      key={news._id}
      className="flex w-full h-full bg-zinc-100 rounded-lg hover:shadow-2xl dark:hover:shadow-md dark:hover:shadow-zinc-700 duration-200 dark:bg-zinc-800 relative"
    >
      {showLikePopup && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-25">
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              delay: 0.2,
              type: "spring",
              stiffness: 60,
              damping: 10,
              duration: 0.8,
              ease: "easeInOut",
            }}
            className="p-4 rounded-lg text-white"
          >
            <HeartFill size={36} />
          </motion.div>
        </div>
      )}
      <article
        id={`news-${news.source_name}`} // Assign ID to the news article
        className="flex xxs:flex-col lg:flex-row w-full h-full"
      >
        {/* DATE */}
        <div className="p-2 xxs:[writing-mode:_horizontal-lr] lg:[writing-mode:_vertical-lr] lg:rotate-180 font-Comfortaa">
          <div className="flex items-center justify-between gap-4 text-xs font-bold uppercase text-zinc-900 dark:text-white">
            <p className="text-xs">{new Date(news.pub_date).getFullYear()}</p>

            <span className="w-px flex-1 bg-parewacreamcanprimary-200"></span>

            <p className="text-xs">
              {new Date(news.pub_date).toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
              })}
            </p>
          </div>
        </div>
        {/* DATE */}

        {/* IMAGE */}
        {imageLoaded && news.image_source && (
          <div className="hidden sm:block xxs:w-full lg:w-[275px] xxs:h-[275px] md:h-full">
            <img
              alt="img_news"
              src={news.image_source}
              className="h-full w-full xxs:object-contain sm:object-cover object-center"
              onError={() => setImageLoaded(false)}
            />
          </div>
        )}
        {/* IMAGE */}

        <div className="flex flex-col justify-around w-full h-full">
          {/* TITLE AND DESCRIPTION */}
          <div className="flex flex-col h-full w-full">
            <span className="m-2 w-fit news-card-category-body">
              {ToTitleCase(news.type || news.category[0] || "Thinking")}
            </span>
            <div className="w-full p-2">
              <h3 className="flex news-card-title mt-2 cursor-text">
                {news.title
                  ? new DOMParser()
                      .parseFromString(news.title, "text/html")
                      .body.textContent.split(" ")
                      .slice(0, 10)
                      .join(" ")
                  : ""}
              </h3>

              <p className="mt-4 news-card-description xxs:h-fit sm:h-20">
                {news.description
                  ? new DOMParser()
                      .parseFromString(news.description, "text/html")
                      .body.textContent.slice(0, 70) + "..."
                  : ""}
              </p>
            </div>
          </div>
          {/* TITLE AND DESCRIPTION */}

          <div className="flex flex-col md:flex-col w-full justify-between font-Comfortaa">
            {/* Published Time and News Source */}
            <div className="items-center w-full px-5 mx-auto py-2">
              <div className="flex-col flex 2xl:flex-row justify-between">
                <div className="flex-col cursor-vertical-text p-1">
                  <p className="text-xs text-zinc-500">
                    {new Date(news.pub_date).toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </p>
                  <p className="xxs:text-[9px] lg:text-[10px] font-light text-zinc-500">
                    Time
                  </p>
                </div>

                <div className="flex p-1 overflow-hidden">
                  <p className="xxs:flex justify-end text-[12px] sm:text-zinc-900 items-center">
                    <img
                      src={sourceImages[news.source_name] || parewaImg}
                      width={25}
                      height={25}
                      className="object-cover object-center rounded-full xxs:w-5 xxs:h-5 lg:w-6 lg:h-6"
                      alt="source_img"
                    ></img>

                    <span
                      onClick={() => toggleSourceName(news.source_name)}
                      className="news-card-source ml-2 cursor-pointer"
                    >
                      {news.source_name}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            {/* Published Time and News Source */}

            {/* BUTTONS AND READ MORE */}
            <div className="flex sm:flex-col 3xl:flex-row w-full font-Comfortaa">
              <CardButtons
                handlePopUpLike={handlePopUpLike}
                news={news}
                setShowLoginAlert={setShowLoginAlert}
                setButtonName={setButtonName}
                buttonName={buttonName}
                className="flex justify-evenly dark:text-zinc-600 text-zinc-300 xxs:w-2/3 sm:w-full 3xl:w-2/3 mb-2"
              />
              <a
                href={`/news/${news?._id}`}
                className="flex bg-parewacreamcanprimary-100 dark:bg-parewacreamcanprimary-300 dark:hover:bg-opacity-50 hover:bg-opacity-50 px-5 py-3 xxs:w-1/3 sm:w-full 3xl:w-2/3 text-left text-xs font-semibold capitalize text-zinc-900 transition"
              >
                Learn more
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
            {/* BUTTONS AND READ MORE */}
          </div>
        </div>
      </article>
    </div>
  );
};

export default NewsWithImage;
