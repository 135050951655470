import { useEffect } from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import "../Terms&Conditions/styles.css";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const watermark = `${bucketUrl}/backend/assets/parewa_logo1.PNG`;

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="font-Comfortaa h-full bg-white dark:bg-zinc-900 relative">
      <main className="xxs:mt-8 sm:mt-20 pb-16 bg-white dark:bg-zinc-900 antialiased h-fit flex flex-col items-center justify-center w-full">
        <Breadcrumbs crumb="Privacy Policy" />
        <div className="flex justify-between mx-auto xxs:p-4 md:p-8 shadow-lg dark:shadow-2xl xxs:rounded-none md:rounded-lg dark:border-2 dark:border-zinc-800 relative">
          <div
            className="absolute inset-0 opacity-5 bg-no-repeat bg-center bg-contain"
            style={{ backgroundImage: `url(${watermark})` }}
          ></div>
          <article className="markdown mx-auto w-full max-w-4xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert dark:text-white">
            <header className="mb-4 lg:mb-6 not-format">
              <h1 className="mb-4 text-3xl font-extrabold leading-tight text-zinc-900 lg:mb-6 lg:text-4xl dark:text-white">
                Privacy Policy
              </h1>
              <p className="text-base text-zinc-500 dark:text-zinc-400">
                Effective Date: 9th October 2024
              </p>
            </header>

            <p className="font-sans font-normal dark:text-white">
              At Parewa, accessible from{" "}
              <a href="https://www.parewa.news">www.parewa.news</a>, one of our
              main priorities is the privacy of our visitors. This Privacy
              Policy outlines the types of information that we collect, how we
              use it, and the steps we take to protect it. By using our website,
              you agree to the collection and use of information in accordance
              with this policy.
            </p>

            <section>
              <h2 className="text-xl font-bold mt-6 mb-3">
                1. Information We Collect
              </h2>
              <ul className="list-disc list-inside space-y-2 mb-4 font-sans font-normal">
                <li>
                  <strong>Personal Information:</strong> When you sign up or
                  interact with our services, we may collect personal
                  information such as your name, email address, and any other
                  details you provide voluntarily.
                </li>
                <li>
                  <strong>Usage Data:</strong> We collect information on how you
                  access and use Parewa, including your IP address, browser
                  type, pages visited, time spent on pages, and other analytical
                  data.
                </li>
                <li>
                  <strong>Cookies and Tracking Technologies:</strong> We use
                  cookies and similar tracking technologies to enhance user
                  experience and analyze website traffic. Cookies are small data
                  files stored on your device that help us recognize you on
                  future visits and provide personalized content.
                </li>
              </ul>

              <h2 className="text-xl font-bold mt-6 mb-3">
                2. How We Use Your Information
              </h2>
              <ul className="list-disc list-inside space-y-2 mb-4 font-sans font-normal">
                <li>
                  <strong>To Improve Our Services:</strong> We analyze usage
                  data to understand how users interact with Parewa and make
                  improvements based on user behavior.
                </li>
                <li>
                  <strong>To Communicate with You:</strong> If you provide your
                  email address, we may use it to send updates, respond to
                  inquiries, or share promotional offers (you may opt-out of
                  marketing emails at any time).
                </li>
                <li>
                  <strong>To Ensure Website Security:</strong> We monitor
                  website activity to detect and prevent fraud or other
                  unauthorized access to our services.
                </li>
                <li>
                  <strong>To Provide Personalized Content:</strong> Cookies and
                  tracking technologies help us deliver personalized content and
                  recommendations based on your preferences and browsing
                  history.
                </li>
              </ul>

              <h2 className="text-xl font-bold mt-6 mb-3">
                3. Sharing Your Information
              </h2>
              <ul className="list-disc list-inside space-y-2 mb-4 font-sans font-normal">
                <li>
                  <strong>Service Providers:</strong> We may share data with
                  trusted service providers who help us operate and maintain our
                  website (e.g., hosting services, analytics providers).
                </li>
                <li>
                  <strong>Legal Obligations:</strong> We may disclose
                  information if required to do so by law, such as in response
                  to a court order or governmental request.
                </li>
                <li>
                  <strong>Business Transfers:</strong> In the event of a merger,
                  acquisition, or asset sale, your personal data may be
                  transferred as part of the business transaction.
                </li>
              </ul>

              <h2 className="text-xl font-bold mt-6 mb-3">
                4. Third-Party Links
              </h2>
              <p className="mb-4 font-sans font-normal">
                Parewa may contain links to third-party websites that are not
                operated by us. We have no control over and assume no
                responsibility for the privacy practices or content of these
                external sites. We encourage you to review the privacy policies
                of any third-party websites you visit.
              </p>

              <h2 className="text-xl font-bold mt-6 mb-3">5. Data Security</h2>
              <p className="mb-4 font-sans font-normal">
                We take the security of your information seriously and implement
                appropriate technical and organizational measures to protect
                your personal data. However, no method of transmission over the
                Internet or electronic storage is completely secure, and we
                cannot guarantee absolute security.
              </p>

              <h2 className="text-xl font-bold mt-6 mb-3">
                6. Your Data Protection Rights
              </h2>
              <p className="mb-4 font-sans font-normal">
                As a user, you have certain rights regarding your personal
                information, including:
              </p>
              <ul className="list-disc list-inside space-y-2 mb-4 font-sans font-normal">
                <li>
                  <strong>Right to Access:</strong> You can request a copy of
                  the personal data we hold about you.
                </li>
                <li>
                  <strong>Right to Rectification:</strong> You can request that
                  we correct any inaccurate or incomplete information.
                </li>
                <li>
                  <strong>Right to Erasure:</strong> You can request the
                  deletion of your personal data, subject to legal requirements.
                </li>
                <li>
                  <strong>Right to Restrict Processing:</strong> You have the
                  right to request the restriction of your personal data in
                  certain circumstances.
                </li>
                <li>
                  <strong>Right to Data Portability:</strong> You can request
                  that your data be transferred to another organization in a
                  structured, machine-readable format.
                </li>
                <li>
                  <strong>Right to Withdraw Consent:</strong> If you have given
                  consent for data processing, you have the right to withdraw
                  that consent at any time.
                </li>
              </ul>
              <p className="mb-4 font-sans font-normal">
                To exercise any of these rights, please contact us at{" "}
                <a href="mailto:info@parewainc.com">info@parewainc.com</a>.
              </p>

              <h2 className="text-xl font-bold mt-6 mb-3">
                7. Changes to This Privacy Policy
              </h2>
              <p className="mb-4 font-sans font-normal">
                We reserve the right to update or modify this Privacy Policy at
                any time. Any changes will be posted on this page with the
                updated effective date. We encourage you to review this policy
                periodically to stay informed of how we are protecting your
                information.
              </p>

              <h2 className="text-xl font-bold mt-6 mb-3">8. Contact Us</h2>
              <p className="mb-4 font-sans font-normal">
                If you have any questions about this Privacy Policy or how we
                handle your personal data, please contact us at:
              </p>
              <p className="mb-4 font-sans font-normal">
                Parewa Incorporated Pvt. Ltd.
                <br />
                Email:{" "}
                <a href="mailto:info@parewainc.com">info@parewainc.com</a>
                <br />
                Address: Chettrapati, Kathmandu, Nepal
              </p>
            </section>
          </article>
        </div>
      </main>
    </div>
  );
};

export default PrivacyPolicy;
