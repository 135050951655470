import { CircleUserRound, Home, Save, Settings2Icon } from "lucide-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainHeroSection from "./MainHeroSection";

const UserProfilePage = () => {
  const theme = useSelector((state) => state.auth.mode);
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState("Profile");

  return (
    <>
      <div
        className={`min-w-screen flex flex-col xxs:mt-6 sm:mt-16 min-h-full ${
          theme === "dark" ? "bg-zinc-900" : "bg-white"
        }`}
      >
        <div className="w-full flex justify-center h-fit font-Comfortaa ">
          <nav className="bg-white shadow dark:bg-zinc-900 w-full flex items-center">
            <div className="flex items-center justify-center p-2 mx-auto text-zinc-600 hover:text-opacity-25 capitalize dark:text-zinc-300 xxs:w-full sm:max-w-3xl">
              <button
                onClick={() => navigate("/home")}
                className="text-zinc-800 xxs:text-xs sm:text-sm h-full flex items-center transition-colors duration-300 transform dark:text-zinc-200 w-fit p-2 mx-1.5 sm:mx-6"
              >
                <Home className="mr-1 w-6 h-6" />
                Home
              </button>

              <button
                onClick={() => setActiveComponent("Profile")}
                className={`${
                  activeComponent === "Profile"
                    ? "bg-zinc-100 dark:bg-zinc-800 text-zinc-800 dark:text-zinc-200 rounded-lg w-fit"
                    : " border-b-2 border-transparent hover:text-zinc-800 transition-colors duration-300 transform dark:hover:text-zinc-200 hover:border-zinc-500 mx-1.5 sm:mx-6"
                } xxs:text-xs sm:text-sm flex items-center p-2`}
              >
                <CircleUserRound className="mr-1 w-6 h-6" />
                Profile
              </button>

              <button
                onClick={() => setActiveComponent("Saved")}
                className={`${
                  activeComponent === "Saved"
                    ? "bg-zinc-100 dark:bg-zinc-800 text-zinc-800 dark:text-zinc-200 rounded-lg"
                    : " border-b-2 border-transparent hover:text-zinc-800 transition-colors duration-300 transform dark:hover:text-zinc-200 hover:border-zinc-500 mx-1.5 sm:mx-6"
                } xxs:text-xs sm:text-sm flex items-center p-2`}
              >
                <Save className="mr-1 w-6 h-6" />
                Saved
              </button>

              <button
                onClick={() => setActiveComponent("UserSettings")}
                className={`${
                  activeComponent === "UserSettings"
                    ? "bg-zinc-100 dark:bg-zinc-800 text-zinc-800 dark:text-zinc-200 rounded-lg"
                    : " border-b-2 border-transparent hover:text-zinc-800 transition-colors duration-300 transform dark:hover:text-zinc-200 hover:border-zinc-500 mx-1.5 sm:mx-6"
                } xxs:text-xs sm:text-sm flex items-center p-2`}
              >
                <Settings2Icon className="mr-1 w-6 h-6" />
                Settings
              </button>
            </div>
          </nav>
        </div>

        {/* BODY */}
        <div
          className={`flex h-fit w-full font-Comfortaa ${
            theme === "dark" ? " bg-zinc-900" : "bg-white"
            // theme === "dark" ? " bg-zinc-900" : "bg-blob bg-no-repeat bg-cover"
          }`}
        >
          {/* HERO SECTION */}
          <MainHeroSection
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
          />
          {/* HERO SECTION */}
        </div>
        {/* BODY */}
      </div>
    </>
  );
};

export default UserProfilePage;
