import { Outlet, Route, Routes, useSearchParams } from "react-router-dom";
import "./App.css";
import NewsByCategories from "./components/CategoriesPage/NewsByCategories.jsx";
import RegisterForm from "./components/Form/LoginForm/RegisterForm";
import EmailVerified from "./components/EmailVerifiedPage/EmailVerified.jsx";
import Welcome from "./components/HomePage/Welcome/Welcome";
import NewsWithSimilarSources from "./components/HomePage/NewsWithSimilarSources.jsx";
import Layout from "./components/Layout";
import Loading from "./components/Loading.jsx";
import LoginPage from "./components/LoginPage/LoginPage";
import NotFound from "./components/NotFound";
import UserProfilePage from "./components/ProfilePage/UserProfilePage";
import ResetPassword from "./components/ResetPasswordPage/ResetPassword.jsx";
import ResetPasswordForm from "./components/ResetPasswordPage/ResetPasswordForm.js";
import Search from "./components/SearchPage/Search.jsx";
import VerifyPage from "./components/VerifyPage/VerifyPage.jsx";
import HomePAGE from "./components/HomePage/Home-Page.jsx";
import VerifyPageNew from "./components/VerifyPage/VerifyPageNew.jsx";
import TermsAndConditions from "./components/Documents/Terms&Conditions/TermAndConditions.jsx";
import PrivacyPolicy from "./components/Documents/PrivacyPolicy/PrivacyPolicy.jsx";
import CopyrightAndIntellectualProperty from "./components/Documents/CopyrightAndIntellectualProperty/CopyrightAndIntellectualProperty.jsx";
import NewsDetailPage from "./components/LearnMore/NewsDetailPage.jsx";

function NewsRoute() {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  return (
    <Layout
      ContentComponent={type ? <NewsByCategories type={type} /> : <Loading />}
    />
  );
}

function App() {
  return (
    <div className="dark:bg-zinc-900 bg-white">
      <Routes>
        <Route path="/" element={<Welcome />}></Route>
        <Route
          path="home"
          element={<Layout ContentComponent={<HomePAGE />} />}
        ></Route>

        <Route path="news" element={<NewsRoute />}></Route>

        <Route path="news" element={<Outlet />}>
          <Route
            path=":newsId"
            element={<Layout ContentComponent={<NewsDetailPage />} />}
          ></Route>
        </Route>

        <Route path="profile" element={<Outlet />}>
          <Route
            path=":id"
            element={<Layout ContentComponent={<UserProfilePage />} />}
          ></Route>
        </Route>
        <Route
          path="search"
          element={<Layout ContentComponent={<Search />} />}
        ></Route>
        <Route path="verify-article" element={<Outlet />}>
          <Route
            path=":id"
            element={<Layout ContentComponent={<VerifyPage />} />}
          ></Route>
        </Route>
        <Route
          path="verify-page"
          element={<Layout ContentComponent={<VerifyPageNew />} />}
        ></Route>

        <Route path="login" element={<LoginPage />}></Route>
        <Route path="register" element={<RegisterForm />}></Route>
        <Route path="email-verified" element={<EmailVerified />}></Route>
        <Route path="reset-password-email" element={<ResetPassword />}></Route>
        <Route
          path="reset-password-form"
          element={<ResetPasswordForm />}
        ></Route>
        <Route
          path="terms-and-conditions"
          element={<Layout ContentComponent={<TermsAndConditions />} />}
        ></Route>
        <Route
          path="privacy-policy"
          element={<Layout ContentComponent={<PrivacyPolicy />} />}
        ></Route>
        <Route
          path="copyright-intellectual-property"
          element={
            <Layout ContentComponent={<CopyrightAndIntellectualProperty />} />
          }
        ></Route>
        <Route
          path="news-with-similar-sources"
          element={<Layout ContentComponent={<NewsWithSimilarSources />} />}
        ></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </div>
  );
}

export default App;
