import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSearchTerm } from "../../slice/authSlice.js";

const SearchBar = ({ debouncedSearch, setDebouncedSearchTerm }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchTerm = useSelector((state) => state.auth.searchTerm);

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    dispatch(setSearchTerm(searchTerm));
    debouncedSearch(searchTerm);

    if (e.key === "Enter") {
      e.preventDefault();
      navigate("/search");
    }
  };

  // const handleCancelSearch = () => {
  //   dispatch(setSearchTerm(""));
  //   navigate("/home");
  // };

  const handleClearSearch = () => {
    dispatch(setSearchTerm(""));
    setDebouncedSearchTerm("");
  };

  return (
    <div className="flex font-Comfortaa w-full">
      <form
        className="flex flex-grow items-center"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="relative w-full hover:shadow-lg shadow-md dark:bg-zinc-800 rounded-lg">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-auto">
            <svg
              className="w-5 h-5 dark:text-white text-zinc-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <div className="flex">
            <input
              type="text"
              id="simple-search"
              className="text-sm rounded-lg block w-full pl-10 p-2.5 
                  dark:bg-zinc-800 dark:border-gray-600 dark:placeholder-white dark:text-white dark:focus:ring-zinc-700
                  text-gray-900 focus:ring-parewacreamcanprimary-100 focus:border-parewacreamcanprimary-200"
              placeholder="Search"
              required
              onClick={() => {
                if (window.location.pathname !== "/search") {
                  navigate("/search");
                }
              }}
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={handleSearchChange}
            ></input>
            {searchTerm && (
              <div>
                {/* <button
                  type="button"
                  onClick={handleCancelSearch}
                  className="absolute inset-y-0 right-12 items-center pr-3 text-black dark:text-white"
                >
                  <span className="text-xs font-semibold ml-2 cursor-pointer hover:underline tracking-wider">
                    Cancel
                  </span>
                </button> */}
                <button
                  type="button"
                  onClick={handleClearSearch}
                  className="absolute inset-y-0 right-0 items-center pr-3 text-black dark:text-white"
                >
                  <span className="text-xs font-semibold ml-2 cursor-pointer hover:underline tracking-wider">
                    Clear
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
