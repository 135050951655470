const FilterButtons = ({ id, onClick, children, active }) => {
  return (
    <button
      key={id}
      id={id}
      onClick={onClick}
      className={`rounded-lg px-2 p-1 duration-500 ease-in-out ${
        active === id ? "bg-zinc-200 dark:bg-zinc-700" : ""
      } `}
    >
      {children}
    </button>
  );
};

export default FilterButtons;
