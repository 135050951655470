import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { newsApi } from "../components/services/api/newsServices.js";
import authSlice from "../slice/authSlice";
import { usersApi } from "../components/services/api/usersService.js";
import likedNewsSlice from "../slice/likedNewsSlice.js";
import typesSlice from "../slice/typesSlice.js";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "likedNews"], // Add the slices you want to persist
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth: authSlice,
    types: typesSlice,
    likedNews: likedNewsSlice,
    [newsApi.reducerPath]: newsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
  })
);

// Initialize the store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([newsApi.middleware, usersApi.middleware, thunk]),
  devTools: false,
});

export const persistor = persistStore(store);
