import React from "react";
import Breadcrumbs from "./Breadcrumbs";
import UserProfile from "./Pages/Profile/Profile-updated.js";
import Saved from "./Pages/Saved/Saved.jsx";
import UserSettingsRTK from "./Pages/UserSettings/UserSettingsRTK";

const MainHeroSection = ({ activeComponent, setActiveComponent }) => {
  return (
    <div className="flex flex-col w-full dark:text-white xxs:p-2 sm:p-0 h-fit">
      <Breadcrumbs
        activeComponent={activeComponent}
        setActiveComponent={setActiveComponent}
      />

      <div className="flex items-center rounded-lg justify-center max-w-screen p-2">
        {/* Active Component selected based on User clicks */}
        {activeComponent === "UserSettings" ? (
          <UserSettingsRTK setActiveComponent={setActiveComponent} />
        ) : activeComponent === "Saved" ? (
          <Saved />
        ) : activeComponent === "ForYou" ? (
          <div>FOR YOU</div>
        ) : activeComponent === "Profile" ? (
          <UserProfile setActiveComponent={setActiveComponent} />
        ) : (
          <div className="xxs:text-xs md:text-lg hover:text-md uppercase p-2 min-h-screen items-center flex mx-auto duration-500 transition-all">
            <div className="flex h-fit p-6">
              <h2 className="">Welcome to your profile</h2>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainHeroSection;
