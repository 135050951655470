import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hitApi } from "../components/services/hitApi";

const initialState = {
  likedNews: [],
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  totalLikes: 0,
  totalDislikes: 0,
};

// Thunk to fetch liked news
export const fetchLikedNews = createAsyncThunk(
  "likedNews/fetchLikedNews",
  async (userId, { getState }) => {
    const state = getState().auth;
    const response = await hitApi.get(`/users/like-activity/${userId}`, {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    return response.data;
  }
);

export const likedNewsSlice = createSlice({
  name: "likedNews",
  initialState,
  reducers: {
    setTotalDislikes: (state, action) => {
      state.totalDislikes = action.payload;
    },
    setTotalLikes: (state, action) => {
      state.totalLikes = action.payload;
    },
    setLikedNews: (state, action) => {
      state.likedNews = action.payload;
    },
    addLikedNews: (state, action) => {
      state.likedNews.push(action.payload);
    },
    removeLikedNews: (state, action) => {
      state.likedNews = state.likedNews.filter(
        (news) => news.newsId !== action.payload.newsId
      );
    },
    updateLikedNews: (state, action) => {
      const index = state.likedNews.findIndex(
        (news) => news.newsId === action.payload.newsId
      );
      if (index !== -1) {
        state.likedNews[index] = action.payload;
      } else {
        state.likedNews.push(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLikedNews.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLikedNews.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.likedNews = action.payload.data.likedNews;
        state.totalLikes = action.payload.data.totalLikes;
        state.totalDislikes = action.payload.data.totalDislikes;
      })
      .addCase(fetchLikedNews.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  addLikedNews,
  removeLikedNews,
  updateLikedNews,
  setLikedNews,
  setTotalLikes,
  setTotalDislikes,
} = likedNewsSlice.actions;

export default likedNewsSlice.reducer;
