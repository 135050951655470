import React from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import FormikInput from "../Form/FormikInput.jsx";
import { motion } from "framer-motion";

const MoreInfo = ({ data, setData }) => {
  const initialValues = {
    city: data.city || "",
    country: data.country || "",
    phoneNumber: data.phoneNumber || "",
  };

  const validationSchema = yup.object({
    city: yup.string(),
    country: yup.string(),
    phoneNumber: yup.number(),
  });

  return (
    <motion.div className="space-y-6" initial={{ x: 500 }} animate={{ x: 0 }}>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ setFieldValue }) => {
            return (
              <Form>
                {/* Location Section */}
                <div className="mt-4">
                  <label className="antialiased block xxs:text-xs sm:text-sm font-extrabold text-gray-700 pb-2">
                    Location
                  </label>
                  {/* City Section */}
                  <FormikInput
                    className="block  h-14 w-full appearance-none bg-zinc-200 rounded-lg border border-gray-100 py-2 px-3 placeholder:text-gray-400 shadow-sm focus:border-zinc-500 focus:outline-none focus:ring-zinc-500 xxs:text-xs sm:text-sm"
                    name="city"
                    type="text"
                    placeholder="City"
                    onChange={(event) => {
                      setData({ ...data, city: event.target.value });
                      setFieldValue("city", event.target.value);
                    }}
                    value={data.city}
                  ></FormikInput>
                  {/* Country Section */}
                  <FormikInput
                    className="block mt-4 h-14 w-full appearance-none bg-zinc-200 rounded-lg border border-gray-100 py-2 px-3 placeholder:text-gray-400 shadow-sm focus:border-zinc-500 focus:outline-none focus:ring-zinc-500 xxs:text-xs sm:text-sm"
                    name="country"
                    type="text"
                    placeholder="Country"
                    onChange={(event) => {
                      setData({ ...data, country: event.target.value });
                      setFieldValue("country", event.target.value);
                    }}
                    value={data.country}
                  ></FormikInput>
                </div>

                {/* Phone Number Section */}
                <div className="mt-4">
                  <label className="antialiased block xxs:text-xs sm:text-sm font-extrabold font text-gray-700 pb-2">
                    Phone Number
                  </label>
                  <FormikInput
                    className="block h-14 w-full appearance-none bg-zinc-200 rounded-lg border border-gray-100 py-2 px-3 placeholder:text-gray-400 shadow-sm focus:border-zinc-500 focus:outline-none focus:ring-zinc-500 xxs:text-xs sm:text-sm"
                    name="phoneNumber"
                    type="text"
                    placeholder="Phone Number"
                    onChange={(event) => {
                      setData({ ...data, phoneNumber: event.target.value });
                      setFieldValue("phoneNumber", event.target.value);
                    }}
                    value={data.phoneNumber}
                  ></FormikInput>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </motion.div>
  );
};

export default MoreInfo;
