import { sources } from "../../../config/config.js";

export const Statistic = () => {
  return (
    <div className="px-4 py-20 mx-auto w-full md:px-24 lg:px-8 bg-zinc-100 dark:bg-zinc-800 font-Comfortaa shadow-lg rounded-lg flex justify-evenly">
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2 h-full">
        <div className="relative xxs:mr-0 lg:mr-5 flex justify-center">
          <svg
            className="absolute xxs:w-full lg:max-w-lg text-parewamatrixprimary-200 "
            fill="currentColor"
            viewBox="0 0 600 392"
          >
            <rect x="0" y="211" width="75" height="181" rx="8" />
            <rect x="525" y="260" width="75" height="132" rx="8" />
            <rect x="105" y="83" width="75" height="309" rx="8" />
            <rect x="210" y="155" width="75" height="237" rx="8" />
            <rect x="420" y="129" width="75" height="263" rx="8" />
            <rect x="315" y="0" width="75" height="392" rx="8" />
          </svg>
          <svg
            className="relative xxs:w-full lg:max-w-lg text-parewacreamcanprimary-200"
            fill="currentColor"
            viewBox="0 0 600 392"
          >
            <rect x="0" y="311" width="75" height="81" rx="8" />
            <rect x="525" y="351" width="75" height="41" rx="8" />
            <rect x="105" y="176" width="75" height="216" rx="8" />
            <rect x="210" y="237" width="75" height="155" rx="8" />
            <rect x="420" y="205" width="75" height="187" rx="8" />
            <rect x="315" y="83" width="75" height="309" rx="8" />
          </svg>
        </div>
        <div className="flex flex-col justify-center">
          <div className="max-w-lg mb-6">
            <div>
              <p className="inline-block mb-4 text-xs font-semibold tracking-wider text-parewacreamcanprimary-200 rounded-full">
                Hmmmmm..
              </p>
            </div>

            <div className="w-full mb-12 xl:mb-0 xl:pr-16">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-black dark:text-white sm:text-4xl sm:leading-none">
                Empower Yourself with <br className="hidden md:block" />
                Verified News
              </h2>
              <p className=" mb-4 text-base font-sans text-zinc-700 dark:text-zinc-200 font-semibold md:text-lg">
                In today's world, misinformation spreads like wildfire. Don't
                let false narratives shape your views. Use our powerful{" "}
                <span className="text-parewacreamcanprimary-200 font-Comfortaa">
                  Parewa
                </span>{" "}
                to check the authenticity of any national article against{" "}
                <span className="text-red-500 text-sm">*</span> {`${sources}`}{" "}
                <span className="text-red-500 text-sm">*</span> trusted sources.
                Stay informed, stay accurate, and help foster a community of
                truth by verifying the news before sharing it. Take control of
                the information you consume and ensure you're contributing to a
                well-informed society.
              </p>
              <p className="text-xs text-red-500 tracking-wider">
                * more news sources on the way *
              </p>
            </div>
          </div>
          <a
            href="/"
            aria-label=""
            className="news-card-learn-more-button xxs:w-full lg:max-w-lg"
          >
            Learn more
            <svg
              className="inline-block w-3 ml-2"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};
