import { Heart } from "@styled-icons/bootstrap/Heart";
import { HeartFill } from "@styled-icons/bootstrap/HeartFill";
import { Save } from "@styled-icons/evaicons-solid/Save";
import { HeartDislike as HeartDislikeOutline } from "@styled-icons/ionicons-outline/HeartDislike";
import { HeartDislike as HeartDislikeSolid } from "@styled-icons/ionicons-solid/HeartDislike";
import {
  ArrowUpRightFromCircle,
  BadgeHelp,
  MoreVertical,
  Trash,
} from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hitApi } from "../../../components/services/hitApi.js";
import useToken from "../../../hooks/useToken.js";
import useUser from "../../../hooks/useUser.js";
import { removeSavedNews, setShowModal } from "../../../slice/authSlice.js";
import { updateLikedNews } from "../../../slice/likedNewsSlice.js";

const CardButtons = ({
  news,
  className,
  getSavedNews,
  setShowLoginAlert,
  setButtonName,
  handlePopUpLike,
}) => {
  const token = useToken();
  const { user } = useUser();
  const [showDropdown, setShowDropdown] = useState(false);
  const [likedStatus, setLikedStatus] = useState(null);
  const [isSaved, setIsSaved] = useState(news.isSaved); // Add state for isSaved
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userId = user?._id;

  // Determine the liked status from the likedNews array
  useEffect(() => {
    if (user && user.likedNews) {
      const likedNewsItem = user.likedNews.find(
        (item) => item.newsId._id === news._id
      );
      setLikedStatus(likedNewsItem ? likedNewsItem.action : null);
    }
  }, [user, news._id]);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const deleteSavedNews = async (news_id) => {
    if (!user) {
      setShowLoginAlert(true);
      return;
    }

    try {
      // Update local state immediately
      setIsSaved(false);
      // eslint-disable-next-line
      const response = await hitApi({
        method: "DELETE",
        url: `/users/news/save-activity/${userId}`,
        ACTION: news.isSaved ? "DELETE" : "SAVE",
        data: { news_id, user_id: userId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Update the Redux state
      dispatch(removeSavedNews(news_id));
      getSavedNews();
    } catch (error) {
      console.error("Something wrong removing saved news", error.message);
      // Revert local state if API call fails
      setIsSaved(true);
    }
  };

  const handleLike = async (ACTION) => {
    if (!user) {
      setShowLoginAlert(true);
      return;
    }

    // Update local state immediately
    setLikedStatus(
      ACTION === "LIKE" ? "LIKE" : ACTION === "DISLIKE" ? "DISLIKE" : null
    );

    try {
      // Make an API call to update the liked news in the database
      await hitApi({
        method: "POST",
        url: `/users/like-activity`,
        data: { userId: userId, newsId: news._id, action: ACTION },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Dispatch the setShowModal action to show the modal
      dispatch(setShowModal(true));
      // Update the Redux state after the API call is successful
      dispatch(updateLikedNews({ newsId: news._id, action: ACTION }));
      setTimeout(() => dispatch(setShowModal(false)), 3000);
    } catch (error) {
      console.error("Error updating liked news:", error.message);
      // Revert local state if API call fails
      setLikedStatus(news.liked);
    }
  };

  const saveNews = async (newsId) => {
    if (!user) {
      setShowLoginAlert(true);
      return;
    }

    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");

    // Update local state immediately
    setIsSaved(true);

    try {
      await hitApi.post(
        `/users/news/save-activity/`,
        {
          user_id: userId,
          news_id: newsId,
          ACTION: "SAVE",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowModal(true);
      setTimeout(() => setShowModal(false), 3000);
    } catch (error) {
      console.error("Error:", error.message);
      // Revert local state if API call fails
      setIsSaved(false);
    }
  };

  const goToNewsSource = () => {
    const a = document.createElement("a");
    a.href = news.source_url;
    const baseUrl = `${a.protocol}//${a.hostname}`;
    window.open(baseUrl, "_blank");
  };

  const verifyPage = () => {
    navigate("/verify-page");
  };

  // Use the liked field from the news object
  const isLiked = likedStatus === "LIKE";
  const isDisliked = likedStatus === "DISLIKE";

  return (
    <div className={className}>
      <button
        className="p-1 cursor-pointer hover:scale-105 duration-300 ease-in-out"
        title="Like"
        onClick={() => {
          const action = isLiked ? "UNLIKE" : "LIKE";
          handleLike(action);
          handlePopUpLike(true);
          setButtonName(action === "LIKE" ? "Like" : "Unlike");
        }}
      >
        {isLiked ? (
          <HeartFill className="text-red-600" size={20} strokeWidth={2} />
        ) : (
          <Heart strokeWidth={2} size={20} />
        )}
      </button>
      <button
        className="p-1 hover:text-zinc-800 dark:hover:text-zinc-900 cursor-pointer hover:scale-105 duration-300 ease-in-out"
        title="Dislike"
        onClick={() => {
          const action = isDisliked ? "UNDISLIKE" : "DISLIKE";
          handleLike(action);
          setButtonName(action === "DISLIKE" ? "Dislike" : "Undislike");
        }}
      >
        {isDisliked ? (
          <HeartDislikeSolid size={24} strokeWidth={2} />
        ) : (
          <HeartDislikeOutline size={24} strokeWidth={2} />
        )}
      </button>

      {news ? (
        isSaved ? (
          <button
            className="hover:text-parewacreamcanprimary-100 dark:hover:text-parewacreamcanprimary-100 cursor-pointer hover:scale-105 duration-300 ease-in-out"
            title="Delete from Saved"
            onClick={() => deleteSavedNews(news._id)}
          >
            <Trash size={24} strokeWidth={2} />
          </button>
        ) : (
          <button
            className="hover:text-parewacreamcanprimary-200 dark:hover:text-parewacreamcanprimary-200 cursor-pointer hover:scale-105 duration-300 ease-in-out"
            title="Save"
            onClick={() => {
              saveNews(news._id);
              setButtonName("Save");
            }}
          >
            <Save size={24} strokeWidth={2} />
          </button>
        )
      ) : null}

      <div className="relative flex" ref={dropdownRef}>
        <button
          className="hover:text-zinc-800 dark:hover:text-parewacreamcanprimary-100 cursor-pointer"
          title="More Options"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <MoreVertical size={24} strokeWidth={2} />
        </button>

        {showDropdown && (
          <div className="origin-top-right z-50 absolute top-2 right-0 mt-2 w-56 rounded-md shadow-xl bg-zinc-100 dark:bg-zinc-800 border-black rounded-lg ring-1 ring-black ring-opacity-5">
            <div
              className="p-2 "
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <button
                className="px-4 py-2 text-sm flex gap-2 items-center text-zinc-700 w-full dark:text-zinc-400 dark:hover:bg-zinc-700 dark:hover:bg-opacity-50 hover:bg-zinc-200 rounded-lg"
                role="menuitem"
                onClick={goToNewsSource}
              >
                <ArrowUpRightFromCircle size={15} />
                <span className="text-[11px] tracking-wider font-semibold p-1">
                  {`Go to ${news.source_name}`}
                </span>
              </button>

              <button
                className="flex  gap-2 px-4 py-2 text-sm items-center dark:hover:text-zinc-900 text-zinc-700 w-full dark:text-zinc-400 dark:hover:bg-green-200 dark:hover:bg-opacity-60 hover:bg-green-200 rounded-lg"
                role="menuitem"
                onClick={verifyPage}
              >
                <BadgeHelp size={15} />{" "}
                <span className="text-[11px] tracking-wider font-semibold p-1">
                  Verify Page
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardButtons;
