import { BadgeHelp, Home, Minus } from "lucide-react";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useGetNewsByIdQuery } from "../services/api/newsServices";

const VerifyPage = () => {
  const { id } = useParams();
  const { data: newsData, isLoading } = useGetNewsByIdQuery(id);

  let newsItem = newsData?.data;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-blob dark:bg-none bg-no-repeat bg-right bg-white dark:bg-zinc-900 min-h-screen mt-20 font-Comfortaa">
      <div className="h-full xxs:flex-col md:flex-row gap-2 justify-start p-2 dark:bg-opacity-70">
        <div className="flex xxs:flex-col 2xl:flex-row w-full gap-2">
          <div className="flex flex-col gap-4 xxs:w-full 2xl:w-1/3">
            {/* DASHBOARD */}
            <div className="h-fit w-full p-2 bg-zinc-200 dark:bg-zinc-800 dark:bg-opacity-50 bg-opacity-25 shadow-lg">
              {/* BREADCRUMBS */}
              <div className="flex justify-center w-full">
                <p className="text-[14px] font-semibold text-zinc-900 dark:text-zinc-200 flex shadow-lg subpixel-antialiased px-2">
                  <Link to="/home" className="flex items-center gap-2 p-2">
                    <Home size={18} />
                    Home
                  </Link>
                  <span className="p-2">
                    <Minus
                      size={32}
                      className="text-parewacreamcanprimary-100 dark:text-parewacreamcanprimary-100"
                    />
                  </span>
                  <Link className="flex items-center gap-2 text-xs">
                    <BadgeHelp size={18} />
                    Verify News Article
                  </Link>
                </p>
              </div>
              {/* BREADCRUMBS */}

              {/* DASHBOARD */}
              <div className="h-fit p-2">
                <h2 className="text-2xl font-bold title dark:text-parewacreamcanprimary-200">
                  Dashboard
                </h2>
                <div className="flex p-2">
                  {/* <div className="flex-col justify-center items-center gap-2 p-2">
                  <h3 className="text-lg font-semibold dark:text-parewamatrixsecondary-400">
                    Total Articles
                  </h3>
                  <span className="text-lg font-bold dark:text-parewamatrixsecondary-400">
                    100
                  </span>
                </div> */}
                  <div className="flex-col w-1/2 flex justify-center items-center gap-2">
                    <h3 className="text-xs flex justify-center font-semibold text-zinc-900 dark:text-zinc-200 w-full ">
                      Verified Articles
                    </h3>
                    <span className="text-lg font-bold text-zinc-900 dark:text-zinc-200">
                      2
                    </span>
                  </div>
                  <div className="flex-col w-1/2 flex justify-center items-center gap-2">
                    <h3 className="text-xs flex justify-center font-semibold text-zinc-900 dark:text-zinc-200 w-full">
                      Unverified Articles
                    </h3>
                    <span className="text-lg font-bold text-zinc-900 dark:text-zinc-200">
                      25
                    </span>
                  </div>
                </div>
              </div>
              {/* DASHBOARD */}
            </div>
            {/* DASHBOARD */}
            <div>
              <div className="w-full flex items-center bg-zinc-200 dark:bg-zinc-800 dark:bg-opacity-50 bg-opacity-25 shadow-md text-black dark:text-white px-3 rounded-lg">
                <div className="flex-col">
                  <h2 className="text-2xl font-bold mb-4 title dark:text-parewacreamcanprimary-200">
                    Verify News Article
                  </h2>
                  <p className="mb-6">
                    You are about to verify the following news article. The
                    verification process involves going across various sources
                    on the internet and checking for the gist of the News
                    article. <br />
                    <br />
                    Please review the article carefully before proceeding.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* NEWS ARTICLE */}
          <div className="flex-col h-full flex gap-2 shadow-md justify-center p-2 xxs:w-full 2xl:w-2/3 bg-zinc-100 dark:bg-zinc-800">
            {/* IMAGE */}
            {newsItem.image_source && (
              <div className="w-full flex justify-center h-fit">
                <div className="relative mb-6 overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg dark:shadow-black/20">
                  <div>
                    <img
                      src={newsItem.image_source}
                      className="w-full"
                      alt="news"
                    />

                    <a href="#!">
                      <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,.15)]"></div>
                    </a>
                  </div>
                </div>
              </div>
            )}
            {/* IMAGE */}

            {/* BODY */}
            <div className="h-fit flex xxs:flex-col sm:flex-row justify-end p-6">
              <div className="xxs:w-full sm:w-1/3 flex-col p-4">
                <h2 className="text-black dark:text-white">
                  Source:{" "}
                  <span className="text-parewacreamcanprimary-200">
                    {newsItem.source_name}
                  </span>
                </h2>
                <div className="flex w-fit mt-4 items-center gap-4 sm:gap-6 mb-2">
                  <div className="flex flex-col cursor-vertical-text p-1">
                    <p className="text-xs text-gray-500">
                      {new Date(newsItem.pub_date).toLocaleDateString()}
                    </p>
                    <p className="text-[8px] font-light text-gray-600">
                      Published Date
                    </p>
                  </div>

                  {newsItem.source_name !== "Kathmandu Post" &&
                    newsItem.source_name !== "Annapurna Post" && (
                      <div className="flex flex-col cursor-vertical-text p-1">
                        <p className="text-xs text-gray-500">
                          {new Date(newsItem.pub_date).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </p>
                        <p className="text-[8px] font-light text-gray-600">
                          Time
                        </p>
                      </div>
                    )}
                </div>
              </div>
              <div className="xxs:w-full sm:w-2/3 dark:text-zinc-300 flex-col shadow-lg p-4">
                <span className="text-[10px]">Title</span>
                <h5 className="mb-3 text-lg font-bold">
                  {newsItem.title
                    ? new DOMParser().parseFromString(
                        newsItem.title,
                        "text/html"
                      ).body.textContent
                    : ""}
                </h5>

                <span className="text-[10px]">Description</span>
                <p className="mb-6">
                  {newsItem.description
                    ? new DOMParser().parseFromString(
                        newsItem.description,
                        "text/html"
                      ).body.textContent
                    : ""}
                </p>
              </div>
            </div>
            {/* BODY */}

            <div className="w-full h-fit border-2 border-black">
              <button className="w-full h-full py-3 font-medium tracking-widest text-white uppercase bg-black shadow-lg focus:outline-none hover:bg-gray-900 hover:shadow-none">
                Verify Article
              </button>
            </div>
          </div>
          {/* NEWS ARTICLE */}
        </div>

        <div className="w-full h-fit mt-2 border-2 border-black">
          <button className="w-full h-full py-3 font-medium tracking-widest text-white uppercase bg-black shadow-lg focus:outline-none hover:bg-gray-900 hover:shadow-none">
            Add More to Verify
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyPage;
