import { Code2, GraduationCap, Navigation } from "lucide-react";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import useToken from "../../../../hooks/useToken.js";
import useUser from "../../../../hooks/useUser.js";
import useUserActivities from "../../../../hooks/useUserActivities.js";
import useUserImage from "../../../../hooks/useUserImage.js";
import Activity from "./Activity/Activity.jsx";
import Loading from "../../../../components/Loading.jsx";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const bg = `${bucketUrl}/backend/assets/wallpapers/raju-photo.jpg`;

export default function Profile({ setActiveComponent }) {
  const token = useToken();
  const { user, loading } = useUser();

  const { userImage, loading: imageLoading } = useUserImage(
    user?._id,
    token,
    true
  );
  const { likeActivities, loading: activitiesLoading } = useUserActivities(
    user?._id,
    token,
    true
  );
  const [activeTab, setActiveTab] = useState("Activity");

  const {
    about,
    username,
    firstName,
    lastName,
    location,
    occupation,
    savedNewsCount,
    totalLikes,
    education,
  } = user || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ zIndex: 9999 }} // Ensure the toast is above other elements
      />
      <div className="w-screen relative -mx-4">
        <main className="flex flex-col w-full">
          {/* Background Photo */}
          <section className="flex h-96 w-full">
            <img
              className="w-full h-full bg-center bg-no-repeat"
              src={bg}
              alt="bg"
            ></img>
          </section>
          {/* Background Photo */}

          {/* BODY */}
          <section className="relative py-16 mt-16">
            <div className="mx-auto px-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white dark:bg-zinc-800 dark:bg-opacity-25 bg-opacity-25 w-full shadow-xl rounded-lg -mt-64">
                <div className="px-6">
                  <div className="flex flex-wrap justify-center">
                    {/* Profile Picture */}
                    <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                      <div className="relative p-2 w-full xxs:w-48 xxs:h-20 sm:w-48 sm:h-20 flex justify-center ">
                        <img
                          alt="user.img"
                          src={userImage}
                          className="shadow-xl rounded-full h-[200px] align-middle border-none bg-zinc-200 dark:bg-zinc-800 dark:bg-opacity-50 bg-opacity-25 absolute -m-16 -ml-20 lg:-ml-16 "
                        />
                      </div>
                    </div>
                    {/* Profile Picture */}

                    {/* Stats */}
                    <div className="w-full lg:w-4/12 lg:order-1 flex items-center">
                      <div className="flex justify-evenly xxs:mt-6 lg:mt-0 xxs:pt-12 lg:pt-0 w-full gap-3">
                        <div className="text-center">
                          <span className="text-xl font-bold block uppercase tracking-wide text-black dark:text-white">
                            {totalLikes}
                          </span>
                          <span className="text-sm text-black dark:text-zinc-300 font-bold">
                            Likes
                          </span>
                        </div>
                        <div
                          className="text-center cursor-pointer"
                          onClick={() => {
                            setActiveComponent("Saved");
                          }}
                        >
                          <span className="text-xl font-bold block uppercase tracking-wide text-black dark:text-white">
                            {savedNewsCount}
                          </span>
                          <span className="text-sm text-black dark:text-zinc-300 font-bold">
                            Saved
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Stats */}

                  {/* User Details */}
                  <div className="w-full flex justify-center h-fit">
                    <div className="text-center xxs:p-0 sm:p-6 xxs:m-4 sm:m-12 xxs:w-full sm:max-w-2xl flex-col flex justify-center">
                      <div className="font-semibold leading-normal mb-2 text-zinc-700 dark:text-zinc-200">
                        <h1 className="xxs:text-xl sm:text-4xl">
                          {firstName} {lastName}
                        </h1>
                        <span className="text-zinc-400 xxs:text-xs sm:text-sm">
                          @{username}
                        </span>
                      </div>
                      <div className="xxs:text-xs sm:text-sm leading-normal mt-0 mb-2 text-zinc-400 dark:text-zinc-200 font-bold uppercase flex items-center justify-between p-1">
                        {/* <i className="fas fa-map-marker-alt mr-2 text-lg text-zinc-400"></i>{" "} */}
                        <Navigation size={17} strokeWidth={3} />
                        {location?.city}, {location?.country}
                      </div>
                      <div className="xxs:text-xs sm:text-sm leading-normal mt-0 mb-2 text-zinc-400 dark:text-zinc-200 font-bold uppercase flex items-center justify-between p-1">
                        <Code2 size={17} strokeWidth={3} />
                        {occupation}
                      </div>
                      <div className="xxs:text-xs sm:text-sm leading-normal mt-0 mb-2 text-zinc-400 dark:text-zinc-200 font-bold uppercase flex items-center justify-between p-1">
                        <GraduationCap size={17} strokeWidth={3} />
                        {education ? (
                          education
                        ) : (
                          <p
                            className="border-b-2 border-dotted cursor-pointer"
                            onClick={() => {
                              setActiveComponent("UserSettings");
                            }}
                          >
                            Add Education
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* User Details */}

                  {/* About */}
                  <div className="py-10 border-t border-zinc-200 text-center">
                    <div className="flex flex-wrap justify-center">
                      <div className="w-full lg:w-9/12 px-4 flex justify-center">
                        <p className="mb-4 xxs:text-sm lg:text-lg leading-relaxed text-zinc-700 dark:text-zinc-200">
                          {about ? (
                            about
                          ) : (
                            <p
                              className="border-b-2 w-fit border-dotted cursor-pointer"
                              onClick={() => {
                                setActiveComponent("UserSettings");
                              }}
                            >
                              Add a little about yourself
                            </p>
                          )}
                        </p>
                        {/* <a
                        href="#pablo"
                        className="font-normal text-zinc-500"
                        onClick={(e) => e.preventDefault()}
                      >
                        Show more
                      </a> */}
                      </div>
                    </div>
                  </div>
                  {/* About */}
                </div>
              </div>
            </div>
          </section>
          {/* BODY */}

          <div className="p-2">
            <ul className="flex flex-wrap xxs:gap-4 lg:gap-6 items-stretch text-lg tracking-wider font-semibold list-none justify-center">
              {/* <li className="flex" onClick={() => setActiveTab("Summary")}>
              <p
                className={`${
                  activeTab === "Summary"
                    ? "text-black dark:text-zinc-400 border-b-2 border-zinc-600 dark:border-zinc-200"
                    : "text-black dark:text-zinc-400"
                } xxs:text-sm sm:text-lg py-5 cursor-pointer transition-colors duration-200 ease-in-out border-b-2 border-transparent hover:border-zinc-900 dark:hover:border-zinc-200`}
              >
                Summary
              </p>
            </li> */}
              <li className="flex" onClick={() => setActiveTab("Activity")}>
                <button
                  // disabled
                  className={`${
                    activeTab === "Activity"
                      ? "text-black dark:text-zinc-400 border-b-2 border-zinc-300 dark:border-zinc-700"
                      : "text-black dark:text-zinc-400"
                  } xxs:text-sm sm:text-lg py-2 p-2 cursor-pointer transition-colors duration-200 ease-in-out border-b-2 border-transparent hover:border-zinc-900 dark:hover:border-zinc-200`}
                >
                  Your Activity
                </button>
              </li>
            </ul>
            <div className="w-full h-fit">
              {/* {activeTab === "Summary" ? <Summary user={user} /> : <Activity />} */}
              {activitiesLoading ? (
                <div className="flex justify-center items-center h-full">
                  <Loading />
                </div>
              ) : (
                activeTab === "Activity" && (
                  <Activity activities={likeActivities} user={user} />
                )
              )}
            </div>
          </div>
        </main>
        {/* <Footer /> */}
      </div>
    </>
  );
}
