import { Minus } from "lucide-react";

const Breadcrumbs = ({ activeComponent, setActiveComponent }) => {
  const componentPaths = {
    UserSettings: ["Profile", "Settings"],
    Saved: ["Profile", "Saved"],
    ForYou: ["Profile", "For You"],
    Edit: ["Profile", "Edit"],
    Profile: ["Profile"],
  };

  const paths = componentPaths[activeComponent] || [
    "Hallo, Namaste, Bonjour, নমস্কার, こんにちは, Salam, Sawasdee",
  ];

  return (
    <nav className="flex tracking-wider h-10 w-full p-2 text-zinc-500 uppercase antialiased justify-center">
      {paths.map((path, index) => (
        <div key={index} className="flex items-center xxs:text-xs">
          {paths[0] ===
          "Hallo, Namaste, Bonjour, নমস্কার, こんにちは, Salam, Sawasdee" ? (
            <div className="flex p-2 items-center antialiased">{path}</div>
          ) : (
            <div
              className="text-black p-1 dark:text-parewacreamcanprimary-100 px-4 cursor-pointer items-center bg-zinc-100 dark:bg-zinc-800 rounded-lg hover:opacity-50"
              onClick={() => setActiveComponent(path)}
            >
              {path}
            </div>
          )}
          {index < paths.length - 1 && (
            <Minus size={32} className="text-parewacreamcanprimary-200" />
          )}
        </div>
      ))}
    </nav>
  );
};

export default Breadcrumbs;
