import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setMode } from "../../../slice/authSlice";
import { Moon, Sun } from "lucide-react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { motion } from "framer-motion";
import { useState } from "react";

// const priyesh = `${process.env.REACT_APP_BUCKET_URL}/backend/assets/pic/priyesh.jpg`;
const bgPhoto = `${process.env.REACT_APP_BUCKET_URL}/backend/assets/parewa_logo1.PNG`;

// const cofounders = [
//   {
//     name: "Aniket Sharma",
//     email: "aniket.poudel@gmail.com",
//     occupation: "Co-Founder",
//     linkedIn: "https://www.linkedin.com/in/aniket-sharma-77b32819a/",
//     quote: "Navigate the Nepali News Universe with Ease!",
//     photo: "https://avatars.githubusercontent.com/u/55676876?v=4",
//   },
//   {
//     name: "Abhishek Jaiswal",
//     email: "abhishekjas2001@gmail.com",
//     occupation: "Co-Founder",
//     linkedIn: "https://www.linkedin.com/in/abhishek-jaswl/",
//     quote:
//       "Soar through information effortlessly, your digital pigeon to the world's insights.",
//     photo: "https://avatars.githubusercontent.com/u/55676876?v=4",
//   },
//   {
//     name: "Priyesh Shrestha",
//     email: "priyeshshrestha9@gmail.com",
//     occupation: "Co-Founder",
//     linkedIn: "https://www.linkedin.com/in/priyeshstha/",
//     quote:
//       "Get reliable news instantly with Parewa, ensuring every story is legit.",
//     photo: priyesh || "https://avatars.githubusercontent.com/u/55676876?v=4",
//   },
// ];

const Welcome = () => {
  const navigate = useNavigate();
  const theme = useSelector((state) => state.auth.mode); // get theme from Redux store
  const dispatch = useDispatch();
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  const toggleTheme = () => {
    dispatch(
      setMode(theme === "light" ? "dark" : "light") // dispatch action to change theme in Redux store
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsRedirecting(true);
      setTimeout(() => {
        navigate("/home");
      }, 1000); // Duration of the slide-up animation
    }, 2000); // Delay before starting the slide-up animation

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <motion.div
      initial={{ y: 0 }}
      animate={isRedirecting ? { y: "-100%" } : { y: 0 }}
      transition={{ duration: 1 }}
      className="relative bg-gradient-to-r from-zinc-900 to-parewacreamcanprimary-200 h-screen max-w-screen text-white overflow-hidden"
    >
      <div className="absolute inset-0 dark:bg-zinc-900">
        <img
          src={bgPhoto}
          alt="Background"
          className="object-contain object-center w-full h-full opacity-30"
        />
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>

      <div className="flex justify-center h-full font-Comfortaa">
        <div className="relative flex flex-col xs:gap-2 md:gap-y-20 justify-center items-center text-center">
          <div className="w-fit flex-col flex xxs:p-0 sm:p-4">
            <h1 className="animate-typing overflow-hidden whitespace-nowrap border-r-4 border-r-white pr-5 xxs:text-xl sm:text-5xl text-white font-bold">
              Welcome to Parewa
            </h1>
            <div className="items-center justify-center w-10 h-10 mx-auto mt-10">
              <button
                className="hover:scale-125 transition-all hover:shadow-xl duration-300 rounded-full"
                onClick={toggleTheme}
              >
                {theme === "light" ? <Moon size={25} /> : <Sun size={25} />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Welcome;
