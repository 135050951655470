import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { PiEye, PiEyeSlash } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import FormikInput from "../Form/FormikInput.jsx";
import PasswordValidation from "../LoginPage/PasswordValidation.jsx";
import { useResetPasswordMutation } from "../services/api/usersService";
import ResetSuccessfullModal from "./Modals/ResetSuccessfullModal.jsx";

const parewaPhoto = `${process.env.REACT_APP_API_URL}/backend/assets/parewa_logo1.PNG`;

const ResetPasswordForm = ({ token }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [isTypingPassword, setIsTypingPassword] = useState(false); // Track if user is typing password
  const [password, setPassword] = useState("");

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  // If token is not passed as a prop, get it from local storage
  if (!token) {
    token = localStorage.getItem("token") || sessionStorage.getItem("token");
  }

  const togglePasswordEye = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordEye = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object({
    password: yup.string().required("Password is required"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await resetPassword({ token: token, password: values.password });
      setSubmitting(false);
      resetForm();
      setIsSubmitted(true); // Set isSubmitted to true when the form is successfully submitted
      setShowModal(true); // Show the modal
    } catch (error) {
      console.log("Error: ", error);
      // Handle error
    }
  };

  // Redirect the user to the login page 3 seconds after the form is submitted
  useEffect(() => {
    if (isSubmitted) {
      setTimeout(() => {
        setShowModal(false);
        navigate("/login");
      }, 3000);
    }
  }, [isSubmitted, navigate]);

  return (
    <div className="font-Comfortaa">
      <ResetSuccessfullModal
        show={showModal}
        onClose={() => setShowModal(false)}
      />
      <section className="bg-white dark:bg-zinc-900">
        <div className="container flex flex-col items-center justify-center min-h-screen px-6 mx-auto">
          <div className="flex justify-center mx-auto">
            <img className="w-auto h-20" src={parewaPhoto} alt=""></img>
          </div>

          <h1 className="mt-4 text-2xl font-semibold tracking-wide text-center text-zinc-800 capitalize md:text-3xl dark:text-white">
            Reset your password with{" "}
            <span className="text-parewacreamcanprimary-200">Parewa's</span>{" "}
            help.
          </h1>

          <div className="w-full max-w-md mx-auto mt-6">
            <div>
              <div className="flex-col flex gap-2">
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                >
                  {({ setFieldValue }) => {
                    return (
                      <Form className="max-w-[400px] w-full mx-auto bg-white dark:bg-zinc-800 p-4 rounded-lg">
                        <div className="flex flex-col gap-2 p-4">
                          <div className="relative">
                            <label className="text-sm text-zinc-600 dark:text-zinc-200 antialiased">
                              Password
                            </label>
                            <FormikInput
                              name="password"
                              type={showPassword ? "text" : "password"}
                              placeholder="Password"
                              onChange={(event) => {
                                setFieldValue("password", event.target.value);
                                setPassword(event.target.value);
                                setIsTypingPassword(
                                  event.target.value.length > 0
                                );
                              }}
                              className="border-2 rounded-lg p-2 w-full placeholder:text-[12px] dark:text-zinc-300"
                            ></FormikInput>
                            <div className="text-2xl text-zinc-200 dark:text-zinc-400 absolute top-8 right-3">
                              {showPassword ? (
                                <PiEyeSlash onClick={togglePasswordEye} />
                              ) : (
                                <PiEye onClick={togglePasswordEye} />
                              )}
                            </div>
                          </div>

                          {isTypingPassword && (
                            <PasswordValidation password={password} />
                          )}

                          <div className="relative">
                            <label className="text-sm text-zinc-600 dark:text-zinc-200 antialiased">
                              Confirm Password
                            </label>
                            <FormikInput
                              name="confirmPassword"
                              type={showConfirmPassword ? "text" : "password"}
                              placeholder="Confirm Password"
                              onChange={(event) => {
                                setFieldValue(
                                  "confirmPassword",
                                  event.target.value
                                );
                              }}
                              className="border-2 rounded-lg p-2 w-full placeholder:text-[12px] dark:text-zinc-300"
                            ></FormikInput>
                            <div className="text-2xl text-zinc-200 dark:text-zinc-400 absolute top-8 right-3">
                              {showConfirmPassword ? (
                                <PiEyeSlash
                                  onClick={toggleConfirmPasswordEye}
                                />
                              ) : (
                                <PiEye onClick={toggleConfirmPasswordEye} />
                              )}
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          disabled={isLoading || isSubmitted}
                          className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-zinc-800 rounded-lg hover:bg-zinc-600 focus:outline-none focus:ring focus:ring-zinc-300 focus:ring-opacity-50"
                        >
                          {isLoading
                            ? "Loading..."
                            : isSubmitted
                            ? "Done"
                            : "Submit"}
                        </button>
                      </Form>
                    );
                  }}
                </Formik>
              </div>

              <p className="mt-6 text-zinc-500 dark:text-zinc-400 text-sm">
                By clicking “Submit” above, you acknowledge that you have read
                and understood, and agree to Our{" "}
                <a
                  href="/terms-conditions"
                  className="text-parewacreamcanprimary-200"
                >
                  Terms & Conditions
                </a>{" "}
                and
                <a href="/privacy" className="text-parewacreamcanprimary-200">
                  {" "}
                  Privacy Policy.
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ResetPasswordForm;
