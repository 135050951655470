import { useEffect } from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import "../Terms&Conditions/styles.css";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const watermark = `${bucketUrl}/backend/assets/parewa_logo1.PNG`;

const CopyrightAndIntellectualProperty = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="font-Comfortaa h-full bg-white dark:bg-zinc-900 relative">
      <main className="xxs:mt-8 sm:mt-20 pb-16 bg-white dark:bg-zinc-900 antialiased h-fit flex flex-col items-center justify-center w-full">
        <Breadcrumbs crumb="Copyright and Intellectual Property" />
        <div className="flex justify-between mx-auto xxs:p-4 md:p-8 shadow-lg dark:shadow-2xl xxs:rounded-none md:rounded-lg dark:border-2 dark:border-zinc-800 relative">
          <div
            className="absolute inset-0 dark:opacity-10 opacity-5 bg-no-repeat bg-center bg-contain"
            style={{ backgroundImage: `url(${watermark})` }}
          ></div>
          <article className="markdown mx-auto w-full max-w-4xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert dark:text-white">
            <header className="mb-4 lg:mb-6 not-format">
              <h1 className="mb-4 text-3xl font-extrabold leading-tight text-zinc-900 lg:mb-6 lg:text-4xl dark:text-white">
                Copyright and Intellectual Property Notice
              </h1>
            </header>

            <p className="font-sans font-normal dark:text-white">
              All content and materials on Parewa (
              <a href="https://www.parewa.news">www.parewa.news</a>), including
              but not limited to text, graphics, logos, images, software, and
              audiovisual elements, are the property of Parewa Incorporated Pvt.
              Ltd. or its content suppliers and are protected by copyright,
              trademark, and other intellectual property laws.
            </p>

            <section>
              <h2 className="text-xl font-bold mt-6 mb-3">
                1. Copyright and Ownership
              </h2>
              <ul className="list-disc list-inside space-y-2 mb-4 font-sans font-normal">
                <li>
                  The overall design, structure, and compilation of the content
                  on Parewa, including the news aggregation software, custom
                  code, and user interface, are owned by Parewa Incorporated
                  Pvt. Ltd.
                </li>
                <li>
                  All original text, graphics, logos, button icons, images,
                  software, and other media on Parewa are the exclusive property
                  of Parewa or used with permission and are protected by
                  international copyright laws.
                </li>
              </ul>

              <h2 className="text-xl font-bold mt-6 mb-3">
                2. Use of Materials
              </h2>
              <ul className="list-disc list-inside space-y-2 mb-4 font-sans font-normal">
                <li>
                  Users may access and view the content for personal,
                  non-commercial use. Copying, reproduction, distribution, or
                  modification of any material on Parewa without express written
                  permission from Parewa Incorporated Pvt. Ltd. is strictly
                  prohibited.
                </li>
                <li>
                  Any unauthorized use of the materials or software may violate
                  copyright, trademark, and other laws and could result in legal
                  consequences.
                </li>
              </ul>

              <h2 className="text-xl font-bold mt-6 mb-3">3. Trademarks</h2>
              <ul className="list-disc list-inside space-y-2 mb-4 font-sans font-normal">
                <li>
                  All trademarks, service marks, and logos displayed on Parewa
                  are the property of Parewa Incorporated Pvt. Ltd. or the
                  respective third-party licensors.
                </li>
                <li>
                  You are not permitted to use any trademarks displayed on
                  Parewa without prior written consent from the trademark owner.
                </li>
              </ul>

              <h2 className="text-xl font-bold mt-6 mb-3">
                4. Software and Code
              </h2>
              <ul className="list-disc list-inside space-y-2 mb-4 font-sans font-normal">
                <li>
                  The software, code, and technologies used in the creation and
                  operation of Parewa are the intellectual property of Parewa
                  Incorporated Pvt. Ltd. or its partners and licensors.
                </li>
                <li>
                  Any reverse engineering, decompiling, or tampering with the
                  software is prohibited and may result in legal action.
                </li>
              </ul>

              <h2 className="text-xl font-bold mt-6 mb-3">
                5. Third-Party Content
              </h2>
              <p className="mb-4 font-sans font-normal">
                Parewa aggregates content from third-party news sources, which
                remain the intellectual property of the respective owners. We do
                not claim ownership of third-party articles, images, or videos
                linked from our website. Any logos, trademarks, or copyrighted
                content from third-party news portals are used solely for
                reference purposes and belong to their respective owners.
              </p>
            </section>
          </article>
        </div>
      </main>
    </div>
  );
};

export default CopyrightAndIntellectualProperty;
