import React from "react";

const SuccessfulModal = ({ open, children }) => {
  return (
    <div
      className={`fixed inset-0 flex w-full justify-center z-30 items-center transition-colors font-Comfortaa ${
        open
          ? "visible dark:bg-zinc-800 bg-zinc-100 bg-opacity-80 dark:bg-opacity-80"
          : "invisible"
      }`}
    >
      <div>
        {React.cloneElement(children, {
          onClick: (e) => {
            e.stopPropagation();
          },
        })}
      </div>
    </div>
  );
};

export default SuccessfulModal;
