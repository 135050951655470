import {
  ActivitySquare,
  Badge,
  Building,
  Code2,
  Map,
  Newspaper,
  Popcorn,
  Trophy,
  UsersRound,
} from "lucide-react";
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import parewaPhoto from "../../../../assets/parewa_logo1.PNG";
import useFetchSavedNews from "../../../../hooks/useFetchSavedNews.js";
import useToken from "../../../../hooks/useToken.js";
import CardButtons from "../../../HomePage/CardButtons/CardButtons.jsx";
import SuccesfulAlert from "../../../HomePage/Modals/SuccessfulAlert.jsx";
import { ToTitleCase } from "../../../HomePage/NewsCard/utils/ToTitleCase.js";
import sourceImages from "../../../../utlis/sourceImages.js";
import useUser from "../../../../hooks/useUser.js";

const categories = [
  { name: "All News", filter: "All", icon: Badge },
  { name: "Arts & Culture", filter: "arts_&_culture", icon: UsersRound },
  {
    name: "Business & Entrepreneurs",
    filter: "business_&_entrepreneurs",
    icon: Building,
  },
  {
    name: "Celebrity & Pop Culture",
    filter: "celebrity_&_pop_culture",
    icon: Popcorn,
  },
  {
    name: "Fitness & Health",
    filter: "fitness_&_health",
    icon: ActivitySquare,
  },
  { name: "News & Social", filter: "news_&_social_concern", icon: Newspaper },
  {
    name: "Science & Technology",
    filter: "science_&_technology",
    icon: Code2,
  },
  { name: "Sports", filter: "sports", icon: Trophy },
  { name: "Travel & Adventure", filter: "travel_&_adventure", icon: Map },
];

const Saved = () => {
  const token = useToken();
  const userHook = useUser();
  const { user, loading } = useMemo(
    () =>
      token
        ? { user: userHook.user, loading: userHook.loading }
        : { user: null, loading: false },
    [userHook.user, userHook.loading, token]
  );
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [active, setActive] = useState("All");
  const [dropdownActive, setDropdownActive] = useState(categories[0].name);

  const { fetchSavedNews, error } = useFetchSavedNews(token, user?._id);

  const [buttonName, setButtonName] = useState("");
  const [showModal, setShowModal] = useState(false);

  const savedNews = useSelector((state) => state.auth.savedNews);

  useEffect(() => {
    if (!user && !loading) {
      navigate("/login");
      return;
    }
  }, [user, loading, navigate]);

  useEffect(() => {
    if (user) {
      fetchSavedNews(
        selectedCategory.filter === "All" ? null : selectedCategory.filter
      );
    }
  }, [selectedCategory, fetchSavedNews, user]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setActive(category.name);
    setDropdownActive(category.name);
  };

  function getOrdinalSuffix(number) {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;
    if (lastDigit === 1 && lastTwoDigits !== 11) {
      return "st";
    }
    if (lastDigit === 2 && lastTwoDigits !== 12) {
      return "nd";
    }
    if (lastDigit === 3 && lastTwoDigits !== 13) {
      return "rd";
    }
    return "th";
  }

  if (!user) {
    return null;
  }

  return (
    <div className="max-w-5xl flex xxs:flex-col sm:flex-row gap-2">
      {/* SIDE BAR */}
      <div className="flex flex-col xxs:hidden sm:flex sm:w-1/3 relative">
        <ul className="flex flex-col w-full sticky top-24">
          {categories.map((category) => {
            const Icon = category.icon;
            return (
              <li key={category.name}>
                <a
                  href="#saved"
                  onClick={() => handleCategoryClick(category)}
                  className={`flex items-center gap-2 px-4 py-3 ${
                    active === category.name
                      ? "text-black dark:text-white font-semibold border-s-[3px] border-zinc-500 bg-zinc-100 dark:bg-zinc-800 "
                      : ""
                  }`}
                >
                  <Icon
                    size={23}
                    className="opacity-75 dark:text-parewacreamcanprimary-200"
                  />
                  <span className="xxs:text-xs lg:text-sm font-medium">
                    {category.name}
                  </span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      {/* SIDE BAR */}

      {/* DROPDOWN */}
      <div className="xxs:flex sm:hidden">
        <li className="w-full list-none">
          <details className="group">
            <summary className="group flex items-center justify-between rounded-lg px-4 py-2 text-zinc-500 dark:text-white hover:bg-zinc-100 dark:bg-zinc-800 hover:text-zinc-700">
              <div className="flex items-center gap-2 w-full py-1">
                <selectedCategory.icon
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-7 w-7 opacity-75"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                />
                <span className="text-sm font-medium">
                  {selectedCategory.name}
                </span>
              </div>
              <span className="shrink-0 transition duration-300 group-open:-rotate-180 border-2 border-zinc-200 dark:border-zinc-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </summary>

            <ul className="py-2 space-y-1 px-4 dark:bg-zinc-800 bg-zinc-100 rounded-b-lg">
              {categories.map((category) => {
                const Icon = category.icon;
                const categoryName = category.name;
                return (
                  <li
                    key={categoryName}
                    className="dark:hover:bg-zinc-700 hover:bg-zinc-200 rounded-sm "
                  >
                    <a
                      href="#saved"
                      onClick={() => handleCategoryClick(category)}
                      className={`flex items-center gap-2 px-4 py-3 border-b-2 dark:border-zinc-700  ${
                        dropdownActive === categoryName
                          ? "text-black dark:text-white font-semibold border-s-[3px] border-zinc-500 bg-zinc-100 dark:bg-zinc-800 "
                          : ""
                      }`}
                    >
                      <Icon
                        size={23}
                        className="opacity-75 dark:text-parewacreamcanprimary-200"
                      />
                      <span className="xxs:text-xs lg:text-sm font-medium">
                        {categoryName}
                      </span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </details>
        </li>
      </div>
      {/* DROPDOWN */}

      {/* BODY */}
      <div className="flex-col">
        {error ? (
          <div className="xxs:w-full lg:w-[200%] min-h-screen p-2 flex justify-center dark:text-white text-parewacreamcanprimary-200">
            No news for this category....yet.
          </div>
        ) : (
          <div className="w-full">
            {savedNews.length === 0 ? (
              <div className="p-4 flex h-20 items-center uppercase">
                <h1>
                  Start saving articles to create your personalized reading
                  list!
                </h1>
              </div>
            ) : (
              [...savedNews].reverse().map((news, index) => (
                <div key={index} className="h-fit flex-col w-full">
                  <div className="flex-col px-4 rounded-sm bg-zinc-100 dark:bg-zinc-800 border dark:border-zinc-700 border-zinc-200">
                    <div className="flex justify-between xxs:items-start lg:items-center mt-5 mb-2 xxs:px-2 sm:px-0">
                      <div className="flex xxs:flex-col sm:flex-row ">
                        <div>
                          <a
                            href={`${news.source_url}`}
                            aria-label="Go original post"
                            className="flex items-center"
                          >
                            <div className="flex items-center justify-center w-12 h-12 rounded-sm mr-2 cursor-none">
                              <img
                                src={
                                  sourceImages[news.source_name] || parewaPhoto
                                }
                                alt="portal_logo"
                                className="object-contain w-full h-full"
                              />
                            </div>
                            <span className="news-card-source mr-2 xxs:text-xs lg:text-sm">
                              {news.source_name}
                            </span>
                          </a>
                        </div>

                        <div className="flex items-center p-2 xxs:mt-2 sm:mt-0">
                          <span
                            className="text-[11px] text-zinc-500 font-medium tracking-wide xxs:mt-0 sm:mt-1"
                            dangerouslySetInnerHTML={{
                              __html: (() => {
                                const dateParts = new Date(news.pub_date)
                                  .toDateString()
                                  .split(" ");
                                const dayWithSuffix = `${
                                  dateParts[2]
                                }<sup>${getOrdinalSuffix(dateParts[2])}</sup>`;
                                return `${dateParts[0]}, ${dateParts[1]} ${dayWithSuffix} ${dateParts[3]}`;
                              })(),
                            }}
                          ></span>
                        </div>
                      </div>

                      <a
                        href="/"
                        aria-label="Go Home"
                        title="Category"
                        className="flex items-center xxs:mt-5"
                      >
                        <span className="news-card-category xxs:text-[9px] lg:text-xs">
                          {ToTitleCase(news.type) || "Thinking"}
                        </span>
                      </a>
                    </div>
                    {news.image_source && (
                      <div className="w-full flex xxs:max-h-80 sm:h-80 aspect-square justify-around">
                        <img
                          className="object-fill xxs:w-full sm:w-fit h-full"
                          src={news.image_source}
                          alt="news_img"
                        />
                      </div>
                    )}
                    <div className="p-2 h-full mb-2">
                      <div className="flex-col gap-2 mb-2">
                        <h2 className="news-card-title mb-2 xxs:text-sm sm:text-lg 2xl:text-xl">
                          {news.title
                            ? new DOMParser().parseFromString(
                                news.title,
                                "text/html"
                              ).body.textContent
                            : "NO News Title :("}
                        </h2>
                        <p className="news-card-description 2xl:text-lg sm:text-sm xxs:text-xs">
                          {news.description
                            ? new DOMParser()
                                .parseFromString(news.description, "text/html")
                                .body.textContent.slice(0, 90) + "..."
                            : "NO NEWS DESCRIPTION"}
                        </p>
                      </div>
                      <div className="flex xxs:flex-col sm:flex-row justify-between py-2">
                        <a
                          className="news-card-learn-more-button xxs:text-xs lg:text-sm"
                          href={`${news.source_url}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Learn More
                          <svg
                            className="w-4 h-4 ml-2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14"></path>
                            <path d="M12 5l7 7-7 7"></path>
                          </svg>
                        </a>

                        <CardButtons
                          className="flex items-center xxs:justify-evenly xxs:mt-4 space-x-4 text-zinc-500"
                          news={news}
                          setButtonName={setButtonName}
                          buttonName={buttonName}
                          getSavedNews={() =>
                            fetchSavedNews(
                              selectedCategory.filter === "All"
                                ? null
                                : selectedCategory.filter
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
      {/* BODY */}

      {showModal && (
        <div className="fixed top-auto left-auto inset-2 z-50 max-h-fit max-w-sm">
          <SuccesfulAlert buttonName={buttonName} setShowModal={setShowModal} />
        </div>
      )}
    </div>
  );
};

export default Saved;
