import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "antd";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { fetchTypes } from "../../slice/typesSlice";
import { setMode } from "../../slice/authSlice.js";
import {
  Code2,
  Compass,
  Map,
  Popcorn,
  UsersRound,
  Moon,
  Sun,
} from "lucide-react";
import { Trophy } from "@styled-icons/bootstrap/Trophy";
import { Music } from "@styled-icons/boxicons-regular/Music";
import { LearningApp } from "@styled-icons/fluentui-system-regular/LearningApp";
import { News } from "@styled-icons/fluentui-system-regular/News";
import { NotebookSubsection } from "@styled-icons/fluentui-system-regular/NotebookSubsection";
import { StyleGuide } from "@styled-icons/fluentui-system-regular/StyleGuide";
import { Video } from "@styled-icons/fluentui-system-regular/Video";
import { Fitness } from "@styled-icons/ionicons-outline/Fitness";
import { Business } from "@styled-icons/ionicons-sharp/Business";
import { GameController } from "@styled-icons/ionicons-solid/GameController";
import { Dining } from "@styled-icons/material-outlined/Dining";
import { FamilyRestroom } from "@styled-icons/material-outlined/FamilyRestroom";
import { DevicesOther } from "@styled-icons/material-rounded/DevicesOther";
import { HomeHeart } from "@styled-icons/remix-line/HomeHeart";
import { TravelCase } from "@styled-icons/zondicons/TravelCase";
import { FacebookSquare } from "@styled-icons/fa-brands/FacebookSquare";
import { Instagram } from "@styled-icons/bootstrap/Instagram";

const Sidebar = ({ openDrawer, setOpenDrawer, user }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = decodeURIComponent(queryParams.get("type")); // Decode the type parameter
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categories = useSelector((state) => state.types.types);
  const status = useSelector((state) => state.types.status);
  const theme = useSelector((state) => state.auth.mode);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTypes());
    }
  }, [status, dispatch]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const onClose = () => {
    setOpenDrawer(false);
  };
  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };
  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  const toggleTheme = () => {
    dispatch(
      setMode(theme === "light" ? "dark" : "light") // dispatch action to change theme in Redux store
    );
  };

  const typeIcons = {
    "arts_&_culture": <UsersRound size={25} />,
    "business_&_entrepreneurs": <Business size={25} />,
    "celebrity_&_pop_culture": <Popcorn size={25} />,
    "diaries_&_daily_life": <NotebookSubsection size={25} />,
    family: <FamilyRestroom size={25} />,
    "fashion_&_style": <StyleGuide size={25} />,
    "film_tv_&_video": <Video size={25} />,
    "fitness_&_health": <Fitness size={25} />,
    "food_&_dining": <Dining size={25} />,
    gaming: <GameController size={25} />,
    "learning_&_educational": <LearningApp size={25} />,
    music: <Music size={25} />,
    "news_&_social_concern": <News size={25} />,
    other_hobbies: <DevicesOther size={25} />,
    relationships: <HomeHeart size={25} />,
    "science_&_technology": <Code2 size={25} />,
    sports: <Trophy size={25} />,
    "travel_&_adventure": <TravelCase size={25} />,
    uncertain: <Map size={25} />,
    "youth_&_student_life": <Compass size={25} />,
    // Add more icons as needed
  };

  return (
    <>
      <Drawer
        title="Menu"
        placement="left"
        width={300}
        closable={false}
        onClose={onClose}
        open={openDrawer}
        className="dark:bg-zinc-900 font-Comfortaa dark:text-white"
      >
        <div className="h-[100%]">
          {/* NAV COMPONENT */}
          <ul className="flex flex-col h-1/2 gap-2 p-2 w-full items-center -mt-6">
            {/* Current TIME */}
            <div className="w-fit flex justify-center p-2">
              <p className="text-xs text-gray-500 tracking-wider">
                {currentTime.toLocaleTimeString("en-US", {
                  hour12: true,
                  timeZone: "Asia/Kathmandu",
                })}
              </p>
            </div>
            {location.pathname.startsWith("/profile") && (
              <li
                onClick={() => {
                  navigate("/home");
                }}
                className="w-full uppercase cursor-pointer"
              >
                <div className="flex items-center h-full border-b dark:border-zinc-800 w-full p-1">
                  <button className="p-1 w-full flex items-start xxs:text-xs sm:text-sm dark:text-white text-black h-fit">
                    Home Page
                  </button>
                </div>
              </li>
            )}

            {!location.pathname.startsWith("/profile") && (
              <li
                onClick={() => {
                  navigate(user ? `/profile/${user._id}` : "/login");
                }}
                className="w-full uppercase cursor-pointer"
              >
                <div className="flex items-center border-b dark:border-zinc-800 w-full">
                  <button
                    id="login_button"
                    className="p-2 xxs:text-xs sm:text-sm dark:text-white text-black"
                  >
                    {user ? "Profile" : "Login"}
                  </button>
                </div>
              </li>
            )}

            {!location.pathname.startsWith("/profile") && (
              <li
                onClick={() => {
                  navigate("/search");
                  onClose();
                }}
                className="w-full uppercase cursor-pointer"
              >
                <div className="flex items-center border-b dark:border-zinc-800 w-full">
                  <button className="p-2 xxs:text-xs sm:text-sm dark:text-white text-black">
                    Search
                  </button>
                </div>
              </li>
            )}

            {!location.pathname.startsWith("/profile") && (
              <li
                onClick={showChildrenDrawer}
                className="flex items-center border-b dark:border-zinc-800 w-full uppercase cursor-pointer"
              >
                <button
                  className="p-2 xxs:text-xs sm:text-sm dark:text-white text-black"
                  type="primary"
                >
                  Categories
                </button>
              </li>
            )}
            {!location.pathname.startsWith("/profile") && (
              <li
                onClick={() => {
                  navigate("/verify-page");
                }}
                className="flex items-center border-b dark:border-zinc-800 w-full uppercase cursor-pointer"
              >
                <button
                  className="p-2 xxs:text-xs sm:text-sm dark:text-white text-black"
                  type="primary"
                >
                  Verify Page
                </button>
              </li>
            )}
            {!location.pathname.startsWith("/profile") && (
              <li
                onClick={() => {
                  navigate("/news-with-similar-sources");
                }}
                className="flex items-center border-b dark:border-zinc-800 w-full uppercase cursor-pointer"
              >
                <button
                  className="p-2 xxs:text-xs sm:text-sm dark:text-white text-black"
                  type="primary"
                >
                  Support
                </button>
              </li>
            )}
          </ul>
          {/* NAV COMPONENT */}

          {/* Footer */}
          <ul className="flex flex-col justify-around w-full h-1/2 font-Comfortaa p-2 gap-4">
            {/* MORE */}
            <div className="flex flex-col gap-2">
              <h1 className="w-full mx-auto px-2 tracking-wide uppercase flex justify-end items-center text-[10px] dark:text-parewacreamcanprimary-200 text-zinc-600 xxs:h-5 sm:h-10">
                MORE
              </h1>
              <li className="w-full uppercase">
                <div
                  onClick={() => {}}
                  className="flex items-center border-b dark:border-zinc-800 w-full"
                >
                  <button className="p-2 xxs:text-xs sm:text-sm dark:text-white text-black ">
                    About Us
                  </button>
                </div>
              </li>
              <li className="w-full uppercase">
                <div
                  onClick={() => {}}
                  className="flex items-center border-b dark:border-zinc-800 w-full"
                >
                  <button className="p-2 xxs:text-xs sm:text-sm dark:text-white text-black ">
                    Our Team
                  </button>
                </div>
              </li>
            </div>
            {/* MORE */}

            {/* THEME and FOLLOW US */}
            <div className="border-t dark:border-zinc-800 flex flex-col">
              <li className="uppercase w-full flex-col h-fit flex justify-end items-end px-2 my-2">
                <h1 className="w-full mx-auto tracking-wide uppercase font-Comfortaa flex justify-end items-center text-[10px] dark:text-parewacreamcanprimary-200 text-zinc-600 h-10">
                  Theme
                </h1>

                <button
                  className="hover:scale-105 transition-all shadow-lg duration-300 rounded-full"
                  onClick={toggleTheme}
                >
                  {theme === "light" ? <Moon size={25} /> : <Sun size={25} />}
                </button>
              </li>

              <li className="uppercase w-full flex-col h-fit flex justify-end items-end px-2">
                <h1 className="w-full mx-auto tracking-wide uppercase font-Comfortaa flex justify-end items-center text-[10px] dark:text-parewacreamcanprimary-200 text-zinc-600 h-10">
                  Follow Us
                </h1>

                <div className="w-full flex justify-end gap-2">
                  <a
                    href="https://www.instagram.com/parewaainc/"
                    rel="noreferrer"
                    target="_blank"
                    title="Instagram"
                    className="dark:text-white p-1 text-black transition hover:opacity-30"
                  >
                    <Instagram size={26} />
                  </a>

                  <a
                    href="https://www.facebook.com/profile.php?id=61555923404677"
                    title="Facebook"
                    rel="noreferrer"
                    target="_blank"
                    className="dark:text-white p-1 text-black transition hover:opacity-30"
                  >
                    <FacebookSquare size={26} />
                  </a>

                  {/* <a
                    href="https://twitter.com/"
                    rel="noreferrer"
                    target="_blank"
                    title="Twitter"
                    className="dark:bg-zinc-800 rounded-full p-1 transition dark:hover:bg-opacity-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30 30"
                      aria-hidden="true"
                      className="h-6 w-6"
                    >
                      <path d="M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z" />
                    </svg>
                  </a> */}
                </div>
              </li>
            </div>
            {/* THEME and FOLLOW US */}

            <p className="text-center xxs:text-[10px] sm:text-xs text-gray-500 block mb-10">
              &copy; 2024 Parewa Incorporated Pvt Ltd.
            </p>
          </ul>
          {/* Footer */}
        </div>

        {/* Inside Drawer */}
        <Drawer
          title="Categories"
          placement="left"
          width={320}
          closable={false}
          onClose={onChildrenDrawerClose}
          open={childrenDrawer}
          className="dark:bg-zinc-900 font-Comfortaa dark:text-white border-r dark:border-zinc-800"
        >
          <ul className="h-fit flex flex-col xxs:gap-1 sm:gap-2">
            {categories.map((category) => (
              <Link
                key={category}
                to={`/news?type=${encodeURIComponent(category)}`}
              >
                <li
                  key={category}
                  className={`p-2 flex items-center gap-2 border-b dark:border-zinc-800 capitalize font-Comfortaa hover:text-opacity-50 dark:hover:text-opacity-50 ${
                    type === category
                      ? " bg-zinc-100 dark:bg-zinc-900 rounded-lg dark:text-parewacreamcanprimary-100 text-black"
                      : " dark:text-white text-black"
                  }`}
                >
                  {typeIcons[category]}
                  <p className="text-xs">{category.replace(/_/g, " ")}</p>
                </li>
              </Link>
            ))}
          </ul>
        </Drawer>
        {/* Inside Drawer */}
      </Drawer>
    </>
  );
};
export default Sidebar;
