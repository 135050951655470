export const sources = process.env.REACT_APP_NUMBER_OF_SOURCES;
export const baseURL = process.env.REACT_APP_API_URL;

export const statusOptions = [
  {
    label: "Select Status",
    value: "",
    disabled: true,
  },
  {
    label: "Single",
    value: "single",
  },
  {
    label: "Married",
    value: "married",
  },
];

export const genderOptions = [
  {
    label: "Select Gender",
    value: "",
    disabled: true,
  },
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const filterOptions = [
  {
    label: "Select Filter",
    value: "",
    disabled: true,
  },
  {
    label: "By Default",
    value: "default",
  },
  {
    label: "Title",
    value: "title",
  },
  {
    label: "Source",
    value: "source",
  },
  {
    label: "Date (newest first)",
    value: "date",
  },
];
