import { motion } from "framer-motion";
import CardButtons from "../CardButtons/CardButtons.jsx";
import { ToTitleCase } from "./utils/ToTitleCase.js";
import sourceImages from "../../../utlis/sourceImages.js";
import { useState } from "react";
import { HeartFill } from "@styled-icons/bootstrap/HeartFill";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaImg = `${bucketUrl}/backend/assets/Parewa_BLACK_WHITE.png`;

const NewsWithoutImage = ({
  news,
  setShowLoginAlert,
  setButtonName,
  buttonName,
  toggleSourceName,
}) => {
  const [showLikePopup, setShowLikePopup] = useState(false);

  const handlePopUpLike = (action) => {
    setShowLikePopup(true);
    setTimeout(() => {
      setShowLikePopup(false);
    }, 1000);
  };
  return (
    <motion.div
      key={news._id}
      className="flex w-full h-full rounded-lg hover:shadow-md dark:hover:shadow-md dark:hover:shadow-zinc-700 dark:bg-zinc-800 bg-zinc-100 relative"
      initial={{ y: -150 }}
      animate={{ y: 0 }}
      transition={{
        type: "spring",
        stiffness: 120,
      }}
    >
      {showLikePopup && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-25">
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              delay: 0.2,
              type: "spring",
              stiffness: 60,
              damping: 10,
              duration: 0.8,
              ease: "easeInOut",
            }}
            className="p-4 rounded-lg text-white"
          >
            <HeartFill size={36} />
          </motion.div>
        </div>
      )}
      <div
        id={`news-${news.source_name}`}
        className="flex-col p-2 flex font-sans justify-between w-full"
      >
        <div className="p-4 flex-col w-full">
          <span className="news-card-category-body">
            {ToTitleCase(news.type || news.category[0] || "Thinking")}
          </span>
          <h2 className="flex news-card-title mt-4 mb-2">
            {news.title
              ? new DOMParser()
                  .parseFromString(news.title, "text/html")
                  .body.textContent.split(" ")
                  .slice(0, 20)
                  .join(" ")
              : ""}
          </h2>
          <p className="tracking-wider news-card-description 2xl:h-40 xxs:h-20">
            {news.description
              ? new DOMParser()
                  .parseFromString(news.description, "text/html")
                  .body.textContent.slice(0, 70) + "..."
              : ""}
          </p>
        </div>
        <div className="flex flex-col p-2 gap-2">
          <div className="flex justify-between items-center flex-wrap pb-2 border-b-2 border-zinc-300 dark:border-zinc-700">
            <a
              className="news-card-learn-more-button"
              href={`/news/${news._id}`}
            >
              Learn More
              <svg
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </a>
            {/* <button
              title="Verify Article"
              onClick={() => {
                navigate(`/verify-article/${news._id}`);
              }}
              className="text-black dark:text-zinc-200 mr-3 inline-flex items-center ml-auto leading-none text-sm pr-3 py-1 border-r border-zinc-200 dark:border-black"
            >
              <InfoCircle size={20} />
            </button> */}
          </div>
          <div className="flex flex-col gap-2 w-full justify-between font-Comfortaa">
            <div className="w-full mt-2">
              <div className="items-center flex w-full">
                <img
                  alt="blog"
                  src={sourceImages[news.source_name] || parewaImg}
                  className="w-12 h-12 mx-1 hover:h-20 hover:w-20 rounded-full object-contain object-center hover:p-2 duration-300 ease-in-out"
                ></img>
                <div className="flex-col p-1 ml-1">
                  <span
                    onClick={() => toggleSourceName(news.source_name)}
                    className="news-card-source cursor-pointer text-xs sm:text-xs md:text-sm lg:text-md xl:text-md"
                  >
                    {news.source_name}
                  </span>
                  <div className="text-zinc-400 tracking-widest flex md:flex-col lg:flex-row">
                    <span className="mr-2 text-xs">
                      {new Date(news.pub_date).toLocaleDateString()}
                    </span>
                    {news.source_name !== "Annapurna Post" &&
                      news.source_name !== "Kathmandu Post" &&
                      news.source_name !== "Ajako Artha" && (
                        <span className="text-[10px]">
                          {new Date(news.pub_date).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </span>
                      )}
                  </div>
                </div>
              </div>
            </div>

            <CardButtons
              handlePopUpLike={handlePopUpLike}
              news={news}
              setShowLoginAlert={setShowLoginAlert}
              setButtonName={setButtonName}
              buttonName={buttonName}
              className="flex items-center mt-2 justify-end xxs:justify-evenly lg:justify-evenly xl:justify-evenly gap-2 w-full text-zinc-400"
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default NewsWithoutImage;
