import CategoryFilter from "./CategoryFilter/CategoryFilter";

const Filters = ({
  categories,
  setCategories,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  selectedFilter,
  setSelectedFilter,
}) => {
  const handleCategoryChange = (category) => {
    setCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((cat) => cat !== category)
        : [...prevCategories, category]
    );
  };

  return (
    <div className="space-y-2 font-Comfortaa">
      {/* Exact Words, Exact Phrase, Source Name */}
      <div className="xxs:p-2 sm:p-4 xxs:w-fit sm:w-full h-fit flex xxs:flex-col sm:flex-row justify-center gap-4">
        <label htmlFor="exactWords" className="inline-flex items-center gap-2">
          <input
            type="radio"
            id="exactWords"
            name="searchFilter"
            className="size-5 rounded border-zinc-300"
            checked={selectedFilter === "exactWords"}
            onChange={() => setSelectedFilter("exactWords")}
          />
          <span className="text-sm font-medium text-zinc-700 dark:text-white">
            Exact Words
          </span>
        </label>

        <label
          htmlFor="exactPhrases"
          className="inline-flex items-center gap-2"
        >
          <input
            type="radio"
            id="exactPhrases"
            name="searchFilter"
            className="size-5 rounded border-zinc-300"
            checked={selectedFilter === "exactPhrases"}
            onChange={() => setSelectedFilter("exactPhrases")}
          />
          <span className="text-sm font-medium text-zinc-700 dark:text-white">
            Exact Phrase
          </span>
        </label>

        <label htmlFor="source_name" className="inline-flex items-center gap-2">
          <input
            type="radio"
            id="source_name"
            name="searchFilter"
            className="size-5 rounded border-zinc-300"
            checked={selectedFilter === "sourceName"}
            onChange={() => setSelectedFilter("sourceName")}
          />
          <span className="text-sm font-medium text-zinc-700 dark:text-white">
            Source Name
          </span>
        </label>

        {/* Reset Button */}
        <button
          type="button"
          className="text-xs text-zinc-900 dark:text-white underline underline-offset-4"
          onClick={() => setSelectedFilter("")}
        >
          Reset
        </button>
      </div>
      {/* Exact Words, Exact Phrase, Source Name */}

      {/* Categories */}
      <details className="overflow-hidden rounded border border-zinc-300 dark:border-zinc-800 [&_summary::-webkit-details-marker]:visible">
        <summary className="flex cursor-pointer items-center justify-between gap-2 bg-white dark:bg-zinc-800 p-4 text-zinc-900 dark:text-white transition">
          <span className="text-sm font-medium"> Categories </span>

          <span className="transition group-open:-rotate-180 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4 text-black"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </span>
        </summary>

        <div className="border-t border-zinc-200 dark:border-black bg-white dark:bg-zinc-800">
          <header className="flex items-center justify-between p-4">
            <span className="text-sm text-zinc-700 dark:text-white">
              {" "}
              {categories.length} Selected{" "}
            </span>

            <button
              type="button"
              className="text-sm text-zinc-900 dark:text-white underline underline-offset-4"
              onClick={() => setCategories([])}
            >
              Reset
            </button>
          </header>

          <CategoryFilter
            categories={categories}
            handleCategoryChange={handleCategoryChange}
          />
        </div>
      </details>
      {/* Categories */}

      {/* Date */}
      <details className="overflow-hidden rounded border border-zinc-300 dark:border-zinc-800 [&_summary::-webkit-details-marker]:hidden">
        <summary className="flex cursor-pointer items-center justify-between gap-2 bg-white dark:bg-zinc-800 p-4 text-zinc-900 dark:text-white transition">
          <span className="text-sm font-medium"> Date </span>

          <span className="transition group-open:-rotate-180">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </span>
        </summary>

        <div className="border-t border-zinc-200 dark:border-black bg-white dark:bg-zinc-800 ">
          <header className="flex items-center justify-between p-4">
            <span className="text-sm text-zinc-700 dark:text-white">
              {" "}
              The oldest news article is from 2017{" "}
            </span>

            <button
              type="button"
              className="text-sm text-zinc-900 dark:text-white underline underline-offset-4"
              onClick={() => {
                setDateFrom("");
                setDateTo("");
              }}
            >
              Reset
            </button>
          </header>

          <div className="border-t border-zinc-200 dark:border-black p-4">
            <div className="flex justify-around xxs:flex-col sm:flex-row gap-4">
              <label
                htmlFor="FilterDateFrom"
                className="flex items-center gap-2"
              >
                <span className="text-zinc-900 dark:text-white">From</span>
                <input
                  type="date"
                  id="FilterDateFrom"
                  placeholder="From"
                  className="w-full rounded-md border-zinc-200 p-2 dark:bg-zinc-700 rounded-lg dark:text-white shadow-md sm:text-sm"
                  value={dateFrom}
                  onChange={(e) => setDateFrom(e.target.value)}
                />
              </label>

              <label htmlFor="FilterDateTo" className="flex items-center gap-2">
                <span className="text-zinc-900 dark:text-white">To</span>
                <input
                  type="date"
                  id="FilterDateTo"
                  placeholder="To"
                  className="w-full rounded-md border-zinc-200 p-2 dark:bg-zinc-700 rounded-lg dark:text-white shadow-md sm:text-sm"
                  value={dateTo}
                  onChange={(e) => setDateTo(e.target.value)}
                />
              </label>
            </div>
          </div>
        </div>
      </details>
      {/* Date */}
    </div>
  );
};

export default Filters;
