import { Link } from "react-router-dom";
import { sources } from "../../../config/config.js";
import { BadgeHelp, Home, Minus } from "lucide-react";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const background = `${bucketUrl}/backend/assets/wallpapers/theone.jpg`;

export const HeroVerify = ({
  url,
  setUrl,
  title,
  setTitle,
  description,
  setDescription,
  handleSubmit,
}) => {
  return (
    <div className="relative h-full">
      <img
        src={background}
        className="absolute inset-0 object-cover object-right-bottom w-full h-full dark:blur-lg"
        alt=""
      />

      <div className="relative bg-opacity-75">
        {/* <svg
          className="absolute inset-x-0 -bottom-1 dark:text-zinc-900 text-parewacreamcanprimary-200"
          viewBox="0 0 1160 163"
        >
          <path
            fill="currentColor"
            d="M-164 13L-104 39.7C-44 66 76 120 196 141C316 162 436 152 556 119.7C676 88 796 34 916 13C1036 -8 1156 2 1216 7.7L1276 13V162.5H1216C1156 162.5 1036 162.5 916 162.5C796 162.5 676 162.5 556 162.5C436 162.5 316 162.5 196 162.5C76 162.5 -44 162.5 -104 162.5H-164V13Z"
          />
        </svg> */}
        {/* <div className="">
          <svg
            className="absolute inset-x-0 -bottom-1 text-parewacreamcanprimary-200"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#F3C56D"
              fill-opacity="1"
              d="M0,128L26.7,128C53.3,128,107,128,160,154.7C213.3,181,267,235,320,229.3C373.3,224,427,160,480,144C533.3,128,587,160,640,154.7C693.3,149,747,107,800,122.7C853.3,139,907,213,960,208C1013.3,203,1067,117,1120,106.7C1173.3,96,1227,160,1280,165.3C1333.3,171,1387,117,1413,90.7L1440,64L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"
            ></path>
          </svg>
        </div> */}
        {/* BREADCRUMBS */}
        <div className="flex rounded-lg shadow-md justify-center mt-2">
          <p className="text-[14px] font-semibold text-white flex subpixel-antialiased px-2">
            <Link
              to="/home"
              className="flex items-center gap-2 p-1 xxs:text-xs md:text-sm"
            >
              <Home className="w-4 h-4" />
              <span className="xl:mt-1">Home</span>
            </Link>
            <span className="p-2">
              <Minus
                size={32}
                className="text-parewacreamcanprimary-100 dark:text-parewacreamcanprimary-100"
              />
            </span>
            <span className="px-1 flex items-center gap-2 p-1 xxs:text-xs md:text-sm">
              <BadgeHelp size={20} />
              Verify Page
            </span>
          </p>
        </div>
        {/* BREADCRUMBS */}

        <div className="relative px-4 py-16 mx-auto overflow-hidden sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="flex flex-col items-center justify-between xl:flex-row">
            <div className="w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-7/12">
              {/* <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
                Empower Yourself with <br className="hidden md:block" />
                Verified News
              </h2>
              <p className="max-w-xl mb-4 text-base font-sans text-zinc-200 font-semibold md:text-lg">
                In today's world, misinformation spreads like wildfire. Don't
                let false narratives shape your views. Use our powerful{" "}
                <span className="text-parewacreamcanprimary-200 font-Comfortaa">
                  Parewa
                </span>{" "}
                to check the authenticity of any national article against{" "}
                <span className="text-red-500 text-sm">*</span> {`${sources}`}{" "}
                <span className="text-red-500 text-sm">*</span> trusted sources.
                Stay informed, stay accurate, and help foster a community of
                truth by verifying the news before sharing it. Take control of
                the information you consume and ensure you're contributing to a
                well-informed society.
              </p>
              <p className="text-xs text-red-500 tracking-wider">
                * more news sources on the way *
              </p> */}

              <h2 className="max-w-lg mb-6 xxs:text-2xl md:text-3xl text-white sm:text-4xl sm:leading-none font-sans text-3xl font-bold tracking-tight">
                Don't Let Fake News Fool You
              </h2>
              <p className="xxs:text-sm leading-4 font-sans font-semibold tracking-tight md:text-lg text-zinc-200">
                Tired of being duped by clickbait and fake news? Fear not! With
                our{" "}
                <span className="text-parewacreamcanprimary-200 font-Comfortaa">
                  Parewa
                </span>{" "}
                , you can outsmart misinformation like a pro. <br></br>
                <br></br> Simply copy/paste the URL, Title, and Description, and
                watch as we compare it against {`${sources}`} trusted sources.
                Get the real scoop, skip the nonsense, and join the ranks of the
                well-informed. Because, hey, the truth is always trending!
              </p>
            </div>
            <div className="w-full max-w-xl xl:px-8 xl:w-5/12">
              <div className="dark:bg-zinc-900 bg-white rounded shadow-2xl p-7 sm:p-10">
                <h3 className="mb-4 text-xl dark:text-white font-semibold sm:text-center sm:mb-6 sm:text-2xl">
                  Copy/Paste the following information
                </h3>
                <form>
                  <div className="mb-1 sm:mb-2">
                    <label
                      htmlFor="URL"
                      className="inline-block mb-1 font-medium dark:text-white"
                    >
                      URL
                    </label>
                    <input
                      placeholder="www.whydidntithinkfofthisbefore.com"
                      required
                      type="text"
                      className="flex-grow w-full h-12 px-4 mb-2 placeholder:text-xs transition duration-200 bg-white border border-zinc-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      id="URL"
                      name="URL"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                    />
                  </div>
                  <div className="mb-1 sm:mb-2">
                    <label
                      htmlFor="title"
                      className="inline-block mb-1 font-medium dark:text-white"
                    >
                      Title
                    </label>
                    <input
                      placeholder="A dog was eating a snow cone and paid for it!"
                      required
                      type="text"
                      className="flex-grow w-full h-12 px-4 mb-2 placeholder:text-xs transition duration-200 bg-white border border-zinc-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      id="title"
                      name="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="mb-1 sm:mb-2">
                    <label
                      htmlFor="Description"
                      className="inline-block mb-1 font-medium dark:text-white"
                    >
                      Description
                    </label>
                    <textarea
                      rows={4}
                      required
                      type="text"
                      className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-zinc-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      id="Description"
                      name="Description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                  <div className="mt-4 mb-2 sm:mb-4">
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-black dark:text-white dark:hover:text-parewacreamcanprimary-200 hover:text-parewacreamcanprimary-200 transition duration-300 rounded shadow-md bg-zinc-200 hover:bg-zinc-100 dark:bg-zinc-800 dark:hover:bg-zinc-700 "
                    >
                      Verify News
                    </button>
                  </div>
                  <p className="text-xs text-zinc-600 dark:text-zinc-200 sm:text-sm">
                    Lets see what it is together..
                  </p>
                  <p className="text-xs text-zinc-600 dark:text-zinc-400 font-semibold mt-2">
                    **Psst, we're currently sleuthing through National news
                    only.
                  </p>
                  <p className="text-xs text-zinc-600 dark:text-zinc-400 font-semibold mt-1">
                    **Bear with us! :)
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
