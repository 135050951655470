import { useState } from "react";
import TermsPage from "./TermsPage";

const TermsModal = ({ open, onClose, onAccept }) => {
  const [isChecked, setIsChecked] = useState(false);
  if (!open) return null;

  return (
    <div className="fixed inset-0 bg-zinc-600 bg-opacity-50 flex items-center justify-center p-6">
      <div className="bg-white p-6 rounded-lg shadow-lg xxs:w-full md:max-w-4xl">
        <h2 className="text-xl font-bold mb-2">Terms and Conditions</h2>
        <p className="mb-4 font-sans font-normal">
          Please read and accept our{" "}
          <span className="text-blue-500">Terms and Conditions</span> to proceed
          with the registration.
        </p>
        <div className="overflow-y-auto no-scrollbar h-64 mb-4">
          {/* Add your terms and conditions content here */}
          <p className="xxs:border-b-2 xxs:border-t-2 md:border-2 md:border-zinc-200 xxs:rounded-none md:rounded-lg xxs:p-0 md:p-2">
            <TermsPage />
          </p>
        </div>
        <div className="flex items-center mb-2">
          <input
            type="checkbox"
            id="acceptTerms"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="acceptTerms" className="text-sm">
            I have read the Terms and Conditions
          </label>
        </div>
        <div className="flex justify-end gap-4">
          <button
            onClick={onClose}
            className="bg-zinc-600 text-white px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              if (isChecked) {
                onAccept();
              }
            }}
            className={`px-4 py-2 rounded-lg text-white ${
              isChecked
                ? "bg-parewacreamcanprimary-200"
                : "bg-zinc-400 cursor-not-allowed"
            }`}
            disabled={!isChecked}
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
