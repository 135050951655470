import React from "react";

const LoadingNew = () => {
  return (
    <div className="w-full">
      <section className="bg-white dark:bg-zinc-800">
        <div className="container px-6 py-10 mx-auto animate-pulse">
          <p className="w-48 h-2 mx-auto bg-zinc-200 rounded-lg dark:bg-zinc-700"></p>

          <p className="w-64 h-2 mx-auto mt-4 bg-zinc-200 rounded-lg dark:bg-zinc-700"></p>
          <p className="w-64 h-2 mx-auto mt-4 bg-zinc-200 rounded-lg sm:w-80 dark:bg-zinc-700"></p>

          <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 sm:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3">
            <div className="w-full ">
              <div className="w-full h-64 bg-zinc-300 rounded-lg dark:bg-zinc-600"></div>

              <p className="w-56 h-2 mt-4 bg-zinc-200 rounded-lg dark:bg-zinc-700"></p>
              <p className="w-24 h-2 mt-4 bg-zinc-200 rounded-lg dark:bg-zinc-700"></p>
            </div>

            <div className="w-full ">
              <div className="w-full h-64 bg-zinc-300 rounded-lg dark:bg-zinc-600"></div>

              <p className="w-56 h-2 mt-4 bg-zinc-200 rounded-lg dark:bg-zinc-700"></p>
              <p className="w-24 h-2 mt-4 bg-zinc-200 rounded-lg dark:bg-zinc-700"></p>
            </div>

            <div className="w-full ">
              <div className="w-full h-64 bg-zinc-300 rounded-lg dark:bg-zinc-600"></div>

              <p className="w-56 h-2 mt-4 bg-zinc-200 rounded-lg dark:bg-zinc-700"></p>
              <p className="w-24 h-2 mt-4 bg-zinc-200 rounded-lg dark:bg-zinc-700"></p>
            </div>

            <div className="w-full ">
              <div className="w-full h-64 bg-zinc-300 rounded-lg dark:bg-zinc-600"></div>

              <p className="w-56 h-2 mt-4 bg-zinc-200 rounded-lg dark:bg-zinc-700"></p>
              <p className="w-24 h-2 mt-4 bg-zinc-200 rounded-lg dark:bg-zinc-700"></p>
            </div>

            <div className="w-full ">
              <div className="w-full h-64 bg-zinc-300 rounded-lg dark:bg-zinc-600"></div>

              <p className="w-56 h-2 mt-4 bg-zinc-200 rounded-lg dark:bg-zinc-700"></p>
              <p className="w-24 h-2 mt-4 bg-zinc-200 rounded-lg dark:bg-zinc-700"></p>
            </div>

            <div className="w-full ">
              <div className="w-full h-64 bg-zinc-300 rounded-lg dark:bg-zinc-600"></div>

              <p className="w-56 h-2 mt-4 bg-zinc-200 rounded-lg dark:bg-zinc-700"></p>
              <p className="w-24 h-2 mt-4 bg-zinc-200 rounded-lg dark:bg-zinc-700"></p>
            </div>

            <div className="w-full ">
              <div className="w-full h-64 bg-zinc-300 rounded-lg dark:bg-zinc-600"></div>

              <p className="w-56 h-2 mt-4 bg-zinc-200 rounded-lg dark:bg-zinc-700"></p>
              <p className="w-24 h-2 mt-4 bg-zinc-200 rounded-lg dark:bg-zinc-700"></p>
            </div>

            <div className="w-full ">
              <div className="w-full h-64 bg-zinc-300 rounded-lg dark:bg-zinc-600"></div>

              <p className="w-56 h-2 mt-4 bg-zinc-200 rounded-lg dark:bg-zinc-700"></p>
              <p className="w-24 h-2 mt-4 bg-zinc-200 rounded-lg dark:bg-zinc-700"></p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoadingNew;
