import React from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import FormikInput from "../Form/FormikInput.jsx";
import FormikSelect from "../Form/FormikSelect.jsx";
import { genderOptions } from "../../config/config.js";
import { motion } from "framer-motion";

const PersonalInfo = ({ data, setData, setError }) => {
  const initialValues = {
    firstName: data.firstName || "",
    lastName: data.lastName || "",
    occupation: data.occupation || "",
    dateOfBirth: data.dateOfBirth || "",
    gender: data.gender || "",
  };

  const validationSchema = yup.object({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    occupation: yup.string().required("Occupation is required"),
    dateOfBirth: yup.date().required("Date of Birth is required"),
  });

  return (
    <motion.div className="space-y-6" initial={{ x: 500 }} animate={{ x: 0 }}>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ setFieldValue }) => {
            return (
              <Form>
                {/* First Name Section */}
                <div className="mt-4">
                  <label className="antialiased block xxs:text-xs sm:text-sm font-extrabold text-gray-700 pb-2">
                    First Name
                  </label>
                  <FormikInput
                    className="block h-14 w-full appearance-none bg-zinc-200 rounded-lg border border-gray-100 py-2 px-3 placeholder:text-gray-400 shadow-sm focus:border-zinc-500 focus:outline-none focus:ring-zinc-500 xxs:text-xs sm:text-sm"
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    onChange={(event) => {
                      setData({ ...data, firstName: event.target.value });
                      setFieldValue("firstName", event.target.value);
                      setError(""); // Clear the error message
                    }}
                    required={true}
                    value={data.firstName}
                  ></FormikInput>
                </div>

                {/* Last Name Section */}
                <div className="mt-4">
                  <label className="antialiased block xxs:text-xs sm:text-sm font-extrabold text-gray-700 pb-2">
                    Last Name
                  </label>
                  <FormikInput
                    className="block h-14 w-full appearance-none bg-zinc-200 rounded-lg border border-gray-100 py-2 px-3 placeholder:text-gray-400 shadow-sm focus:border-zinc-500 focus:outline-none focus:ring-zinc-500 xxs:text-xs sm:text-sm"
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    onChange={(event) => {
                      setData({ ...data, lastName: event.target.value });
                      setFieldValue("lastName", event.target.value);
                      setError(""); // Clear the error message
                    }}
                    required={true}
                    value={data.lastName}
                  ></FormikInput>
                </div>

                {/* Occupation Section */}
                <div className="mt-4">
                  <label className="antialiased block xxs:text-xs sm:text-sm font-extrabold text-gray-700 pb-2">
                    Occupation
                  </label>
                  <FormikInput
                    className="block h-14 w-full appearance-none bg-zinc-200 rounded-lg border border-gray-100 py-2 px-3 placeholder:text-gray-400 shadow-sm focus:border-zinc-500 focus:outline-none focus:ring-zinc-500 xxs:text-xs sm:text-sm"
                    name="occupation"
                    type="text"
                    placeholder="Occupation"
                    onChange={(event) => {
                      setData({ ...data, occupation: event.target.value });
                      setFieldValue("occupation", event.target.value);
                      setError(""); // Clear the error message
                    }}
                    required={true}
                    value={data.occupation}
                  ></FormikInput>
                </div>

                {/* Date of Birth Section */}
                <div className="mt-4">
                  <label className="antialiased block xxs:text-xs sm:text-sm font-extrabold text-gray-700 pb-2">
                    Date of Birth
                  </label>
                  <FormikInput
                    className="block h-14 w-full appearance-none bg-zinc-200 rounded-lg border border-gray-100 py-2 px-3 placeholder:text-gray-400 shadow-sm focus:border-zinc-500 focus:outline-none focus:ring-zinc-500 xxs:text-xs sm:text-sm"
                    name="dateOfBirth"
                    type="date"
                    onChange={(event) => {
                      setData({ ...data, dateOfBirth: event.target.value });
                      setFieldValue("dateOfBirth", event.target.value);
                      setError(""); // Clear the error message
                    }}
                    required={true}
                    value={data.dateOfBirth}
                  ></FormikInput>
                </div>

                {/* Gender Section */}
                <div className="mt-4">
                  <label className="antialiased block xxs:text-xs sm:text-sm font-extrabold text-gray-700 pb-2">
                    Gender
                  </label>
                  <FormikSelect
                    className="block h-14 w-full appearance-none bg-zinc-200 rounded-lg border border-gray-100 py-2 px-3 placeholder:text-gray-400 shadow-sm focus:border-zinc-500 focus:outline-none focus:ring-zinc-500 xxs:text-xs sm:text-sm"
                    name="gender"
                    options={genderOptions}
                    onChange={(event) => {
                      setData({ ...data, gender: event.target.value });
                      setFieldValue("gender", event.target.value);
                      setError(""); // Clear the error message
                    }}
                    required={true}
                    value={data.gender}
                  ></FormikSelect>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </motion.div>
  );
};

export default PersonalInfo;
