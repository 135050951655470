// Search.jsx
import { Save } from "@styled-icons/evaicons-solid/Save";
import { Trash } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginModal from "../HomePage/Modals/LoginModal.jsx";
import SuccessfulAlert from "../HomePage/Modals/SuccessfulAlert.jsx";
import { ToTitleCase } from "../HomePage/NewsCard/utils/ToTitleCase";
import Pagination from "../HomePage/Pagination/Pagination.jsx";
import SearchBar from "../SearchBar/SearchBar.js";
import {
  useGetNewsBySearchQuery,
  useGetRecentSearchesQuery,
  useUpdateRecentSearchesMutation,
} from "../services/api/newsServices";
import { hitApi } from "../services/hitApi.js";
import Filters from "./Filters.jsx";
import { setSearchTerm } from "../../slice/authSlice.js";
import { debounce } from "lodash";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaImg = `${bucketUrl}/backend/assets/Parewa_BLACK_WHITE.png`;
const kathmanduPost = `${bucketUrl}/backend/assets/portalLogos/TKP.png`;
const annapurnaPost = `${bucketUrl}/backend/assets/portalLogos/अन्_नपूर्ण_पोस्ट्.png`;
const makaluKhabar = `${bucketUrl}/backend/assets/portalLogos/makalukhabar.jpg`;
const setopati = `${bucketUrl}/backend/assets/portalLogos/setopati.png`;
const gorkhapatra = `${bucketUrl}/backend/assets/portalLogos/gorkhapatra.png`;
const nagarikNews = `${bucketUrl}/backend/assets/portalLogos/nagarik_news.png`;
const imageKhabar = `${bucketUrl}/backend/assets/portalLogos/imagekhabar.jpg`;
const karobardaily = `${bucketUrl}/backend/assets/portalLogos/karobardaily.png`;
const himalayaTimes = `${bucketUrl}/backend/assets/portalLogos/eHimalayaTimes.png`;
const aawaajnews = `${bucketUrl}/backend/assets/portalLogos/aawajnews.png`;
const english_makalu_khabar = `${bucketUrl}/backend/assets/portalLogos/english_makalu_khabar.png`;
const rajdhanidaily = `${bucketUrl}/backend/assets/portalLogos/rajdhanidaily.png`;
const onlinekhabar = `${bucketUrl}/backend/assets/portalLogos/onlinekhabar.png`;
const newsOfNepal = `${bucketUrl}/backend/assets/portalLogos/newsofnepal.png`;
const news24 = `${bucketUrl}/backend/assets/portalLogos/news24.jpg`;
const nepalnews = `${bucketUrl}/backend/assets/portalLogos/nepalnews.jpg`;
const himalkhabar = `${bucketUrl}/backend/assets/portalLogos/himalkhabar.png`;
const hamrokhelkud = `${bucketUrl}/backend/assets/portalLogos/hamrokhelkud.jpg`;
const techmandu = `${bucketUrl}/backend/assets/portalLogos/techmandu.jpg`;
const ratopati = `${bucketUrl}/backend/assets/portalLogos/ratopati.jpeg`;
const therisingNepal = `${bucketUrl}/backend/assets/portalLogos/therisingnepal.png`;
const thehimalayantimes = `${bucketUrl}/backend/assets/portalLogos/thehimalayantimes.png`;
const dekhapadhi = `${bucketUrl}/backend/assets/portalLogos/dekhapadhi.png`;
const drishtinews = `${bucketUrl}/backend/assets/portalLogos/drishtinews.jpg`;
const reportersnepal = `${bucketUrl}/backend/assets/portalLogos/reportersNepal.png`;
const khabarhub = `${bucketUrl}/backend/assets/portalLogos/khabarhub.png`;
const ditible = `${bucketUrl}/backend/assets/portalLogos/ditible.webp`;
const bizmandu = `${bucketUrl}/backend/assets/portalLogos/bizmandu.ico`;
const clickmandu = `${bucketUrl}/backend/assets/portalLogos/clickmandu.jpg`;

const sourceImages = {
  "Aawaaj News": aawaajnews,
  "Kathmandu Post": kathmanduPost,
  "Annapurna Post": annapurnaPost,
  "Makalu Khabar English": english_makalu_khabar,
  "Makalu Khabar Nepali": makaluKhabar,
  "Online Khabar English": onlinekhabar,
  "Online Khabar Nepali": onlinekhabar,
  Setopati: setopati,
  Gorkhapatra: gorkhapatra,
  "Nagarik News": nagarikNews,
  "Himalaya Times": himalayaTimes,
  "Karobar Daily": karobardaily,
  "Image Khabar": imageKhabar,
  "Rajdhani Daily": rajdhanidaily,
  "News of Nepal": newsOfNepal,
  "News 24": news24,
  "Nepal News": nepalnews,
  "Himal Khabar": himalkhabar,
  "Hamro Khelkud": hamrokhelkud,
  Techmandu: techmandu,
  Ratopati: ratopati,
  "The Rising Nepal": therisingNepal,
  "The Himalayan Times": thehimalayantimes,
  Dekhapadhi: dekhapadhi,
  "Drishti News": drishtinews,
  "Reporters Nepal": reportersnepal,
  Khabarhub: khabarhub,
  "The Ditible": ditible,
  Bizmandu: bizmandu,
  Clickmandu: clickmandu,
  // add other source_name: image pairs as needed
};

const Search = () => {
  const searchTerm = useSelector((state) => state.auth.searchTerm);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.auth);
  const user = state.user;
  const [recentSearches, setRecentSearches] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [buttonName, setButtonName] = useState("Save");

  const [categories, setCategories] = useState([]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");

  const navigate = useNavigate();

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  let query = {
    page: currentPage,
    searchTerm: debouncedSearchTerm,
    selectedFilter,
    categories,
    dateFrom,
    dateTo,
  };

  const {
    data: searchData,
    isLoading,
    isError,
  } = useGetNewsBySearchQuery(query);

  const { data: recentSearchesData } = useGetRecentSearchesQuery(undefined, {
    skip: !user,
  });

  const [updateRecentSearches] = useUpdateRecentSearchesMutation();

  useEffect(() => {
    if (user && recentSearchesData) {
      setRecentSearches(recentSearchesData.recentSearches);
    } else {
      const localRecentSearches =
        JSON.parse(localStorage.getItem("recentSearches")) || [];
      setRecentSearches(localRecentSearches);
    }
  }, [user, recentSearchesData]);

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === "hidden" && user) {
  //       updateRecentSearches(recentSearches).catch((error) => {
  //         console.error("Error updating recent searches:", error);
  //       });
  //     }
  //   };

  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, [recentSearches, user, updateRecentSearches]);

  let searchResults = searchData?.data;
  let totalNewsFound = searchData?.totalNews;

  function highlightSearchTerm(text, searchTerm) {
    const regex = new RegExp(`(${searchTerm})`, "gi");
    return text.replace(
      regex,
      '<span style="background-color: #8f9296; color: #F3C56D; padding: 2px;">$1</span>'
    );
  }

  const deleteSavedNews = async (news_id) => {
    const userId = state.user._id;

    try {
      // eslint-disable-next-line
      const response = await hitApi({
        method: "DELETE",
        url: `/users/news/save-activity/${userId}`,
        ACTION: searchResults.isSaved ? "DELETE" : "SAVE",
        data: { news_id, user_id: userId },
      });
    } catch (error) {
      console.error("Something wrong removing saved news", error.message);
    }
  };

  const saveNews = (newsId) => {
    if (!user) {
      setShowLoginAlert(true);
      return;
    }

    const userId = user._id;

    hitApi
      .post(`/news/save/`, {
        user_id: userId,
        news_id: newsId,
        ACTION: searchResults.isSaved ? "DELETE" : "SAVE",
      })
      .then((res) => {
        setShowModal(true);
        setTimeout(() => setShowModal(false), 3000);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const handleRecentSearchClick = (search) => {
    dispatch(setSearchTerm(search));
    debouncedSearch(search);
  };

  useEffect(() => {
    setTotalPages(searchData?.totalPages);
  }, [setTotalPages, searchData]);

  const handleClearRecentSearches = () => {
    localStorage.removeItem("recentSearches");
    setRecentSearches([]);
  };

  // Debounce the search term input
  const debouncedSearch = useCallback(
    debounce((term) => {
      if (!term.trim()) return; // Prevent empty searches
      setDebouncedSearchTerm(term);
      if (user) {
        updateRecentSearches(term);
      } else {
        const localRecentSearches =
          JSON.parse(localStorage.getItem("recentSearches")) || [];
        const updatedSearches = [
          term,
          ...localRecentSearches.filter((search) => search !== term),
        ];
        if (updatedSearches.length > 10) {
          updatedSearches.pop();
        }
        localStorage.setItem("recentSearches", JSON.stringify(updatedSearches));
        setRecentSearches(updatedSearches);
      }
    }, 500), // Adjust the debounce delay as needed
    [user, updateRecentSearches]
  );

  return (
    <div className="xxs:max-w-screen flex justify-center items-start min-h-screen xxs:mt-12 sm:mt-20 p-2 bg-white dark:bg-zinc-900">
      <div className="flex flex-col justify-center xxs:max-w-full lg:max-w-5xl gap-2 bg-white dark:bg-zinc-900">
        <div className="flex xxs:flex-col p-4 xxs:mt-6 sm:mt-0 gap-2">
          {/* Search Bar */}
          <SearchBar
            debouncedSearch={debouncedSearch}
            setDebouncedSearchTerm={setDebouncedSearchTerm}
          />

          {/* Recent Searches */}
          <div className="w-full h-fit p-4 font-Comfortaa">
            <div className="flex items-center dark:text-white">
              <h2 className="text-sm p-1 w-fit">Recent Searches</h2>
              <button
                className="w-fit text-[10px] underline p-1"
                onClick={handleClearRecentSearches}
              >
                Clear
              </button>
            </div>
            <div className="flex gap-1 flex-wrap">
              {recentSearches.map((search, index) => (
                <p
                  className="p-2 w-fit rounded-lg shadow-md hover:shadow-lg dark:hover:shadow-zinc-700 cursor-pointer dark:bg-zinc-800 text-black dark:text-zinc-400 text-sm flex"
                  key={index}
                  onClick={() => handleRecentSearchClick(search)}
                >
                  {search}
                </p>
              ))}
            </div>
          </div>

          {/* Filters */}
          <div className="p-4">
            <Filters
              categories={categories}
              setCategories={setCategories}
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
            />
          </div>
        </div>

        <hr className="border-2 border-zinc-100 dark:border-zinc-700 rounded-full mb-2"></hr>

        {/* News Articles from Search */}
        <div className="flex flex-col items-center h-full">
          <h2 className="w-full p-2 text-center font-Comfortaa xxs:text-xs sm:text-sm font-semibold text-black dark:text-white tracking-wide">
            {debouncedSearchTerm && searchTerm ? (
              <div>{`Search Results for : ${searchTerm}`}</div>
            ) : (
              ""
            )}
            {debouncedSearchTerm && searchTerm && totalNewsFound ? (
              <div>{`${totalNewsFound} articles found`}</div>
            ) : (
              ""
            )}
          </h2>
          {isLoading ? (
            <p>Loading...</p>
          ) : isError ? (
            searchTerm ? (
              <p>Error occurred</p>
            ) : (
              <p
                className="cursor-pointer xxs:text-xs sm:text-sm p-4 rounded-lg hover:bg-opacity-50 dark:hover:bg-opacity-50 font-Comfortaa text-black dark:text-white bg-zinc-100 dark:bg-zinc-800"
                onClick={() => {
                  navigate("/home");
                }}
              >
                Go Back to Home
              </p>
            )
          ) : (
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 mx-3 font-Comfortaa">
              {searchTerm && searchResults && searchResults.length > 0 ? (
                searchResults.slice(0, 16).map((item) => (
                  <div
                    key={item._id}
                    className="flex items-end dark:border-2 dark:border-black rounded-lg hover:shadow-lg dark:bg-zinc-800 bg-zinc-100"
                  >
                    <div className="w-full h-full justify-between flex-col flex">
                      {item.image_source ? (
                        <div className="">
                          <img
                            className="object-contain object-right w-full rounded-sm"
                            src={item.image_source}
                            alt=""
                          ></img>
                        </div>
                      ) : null}

                      <div
                        className={`p-6 ${
                          item.image_source ? "h-fit" : "h-full justify-between"
                        }  w-full flex-col flex`}
                      >
                        <div>
                          <span className="news-card-category-body">
                            {ToTitleCase(item.type) || "Thinking"}
                          </span>

                          <h1 className="mt-4 news-card-title">
                            {item.title ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: highlightSearchTerm(
                                    new DOMParser().parseFromString(
                                      item.title,
                                      "text/html"
                                    ).body.textContent,
                                    searchTerm
                                  ),
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </h1>

                          <p
                            className={`mt-2 news-card-description ${
                              item.image_source ? "" : "h-fit"
                            }`}
                          >
                            {item.description ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    highlightSearchTerm(
                                      new DOMParser()
                                        .parseFromString(
                                          item.description,
                                          "text/html"
                                        )
                                        .body.textContent.slice(0, 180),
                                      searchTerm
                                    ) + "...",
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </p>
                        </div>

                        <div className="flex flex-col">
                          <div className="flex items-center justify-between mt-4">
                            <div className="justify-center flex flex-col xxs:flex-col items-center gap-2 p-1">
                              <div className="flex items-center justify-start w-full">
                                <img
                                  src={
                                    sourceImages[item.source_name] || parewaImg
                                  }
                                  width={25}
                                  height={25}
                                  className="object-cover object-center rounded-full xxs:w-5 xxs:h-5 lg:w-6 lg:h-6"
                                  alt="source_img"
                                ></img>
                                <p className="xxs:flex justify-end text-[12px] sm:text-zinc-900 items-center">
                                  <a
                                    href={`${item.source_url}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="news-card-source ml-2"
                                  >
                                    {item.source_name}
                                  </a>
                                </p>
                              </div>

                              <p className="text-[12px] text-zinc-500 dark:text-zinc-400 flex justify-start w-full">
                                <span className="mr-2">
                                  {new Date(item.pub_date).toLocaleDateString()}
                                </span>
                                {item.source_name !== "Annapurna Post" &&
                                  item.source_name !== "Kathmandu Post" && (
                                    <span>
                                      {new Date(
                                        item.pub_date
                                      ).toLocaleTimeString("en-US", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })}
                                    </span>
                                  )}
                              </p>
                            </div>

                            <div className="items-center flex gap-2">
                              {item ? (
                                item.isSaved ? (
                                  <button
                                    className="hover:text-parewacreamcanprimary-100 dark:hover:text-parewacreamcanprimary-100 cursor-pointer hover:scale-105 duration-300 ease-in-out"
                                    title="Delete from Saved"
                                    onClick={() => deleteSavedNews(item._id)}
                                  >
                                    <Trash size={24} strokeWidth={2} />
                                  </button>
                                ) : (
                                  <button
                                    className="hover:text-parewacreamcanprimary-200 dark:hover:text-parewacreamcanprimary-200 cursor-pointer hover:scale-105 duration-300 ease-in-out text-zinc-400 dark:text-zinc-500"
                                    title="Save"
                                    onClick={() => {
                                      saveNews(item._id);

                                      setButtonName("Save");
                                    }}
                                  >
                                    <Save size={24} strokeWidth={2} />
                                  </button>
                                )
                              ) : null}
                              <a
                                href={`/news/${item._id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="news-card-learn-more-button"
                              >
                                Learn more
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : searchTerm ? (
                <div className="grid col-start-1 col-end-5 h-screen border-black items-start mt-10">
                  <div className="p-6 bg-zinc-100 dark:bg-zinc-800 dark:bg-opacity-60 rounded-lg shadow-md bg-opacity-50 dark:text-white">
                    <h1 className="text-center text-sm">
                      No such news article found.
                    </h1>
                    <p className="text-center text-lg">
                      <span className="dark:text-parewacreamcanprimary-200">
                        Please
                      </span>{" "}
                      try searching with different keywords.
                    </p>

                    <div className="flex items-center justify-between mt-4">
                      <div></div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>

        {/* Pagination */}
        <div>
          {searchTerm && searchResults && (
            <Pagination
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          )}
        </div>

        {showModal && (
          <div className="fixed top-auto left-auto inset-2 z-50 max-h-fit max-w-sm">
            <SuccessfulAlert
              buttonName={buttonName}
              setShowModal={setShowModal}
            />
          </div>
        )}

        {showLoginAlert && (
          <div className="fixed inset-2 z-50 max-h-full  rounded-lg max-w-screen bg-zinc-100 dark:bg-zinc-900 dark:bg-opacity-90 bg-opacity-80 font-Comfortaa flex items-center justify-center">
            <div className="h-fit flex flex-col items-center bg-parewaBackground bg-no-repeat bg-left">
              <div className="flex p-6 w-full justify-center bg-zinc-100 dark:bg-black dark:bg-opacity-80 text-black dark:text-white shadow-md mb-2 rounded-lg">
                You are about to{" "}
                <span className="text-parewacreamcanprimary-200 mr-1 ml-1">
                  {buttonName}
                </span>{" "}
                a news article
              </div>
              <LoginModal setShowLoginAlert={setShowLoginAlert} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
