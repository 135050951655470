import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLogin } from "../../../slice/authSlice";
import LoginForm from "../../Form/LoginForm/LoginForm";
import { hitApi } from "../../services/hitApi";
import SuccessfulModal from "../../LoginPage/Modals/SuccessfulModal";
import { useNavigate } from "react-router-dom";
import {
  setLikedNews,
  setTotalDislikes,
  setTotalLikes,
} from "../../../slice/likedNewsSlice";

const LoginModal = ({ setShowLoginAlert }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    if (token) {
      hitApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    setIsError(false);
  }, []);

  const login = async (values, onSubmitProps) => {
    setIsLoading(true); // Show the loading component
    try {
      const loggedIn = await hitApi({
        method: "POST",
        url: "/users/login",
        data: values,
      });

      const loggedInResponse = loggedIn.data;
      const loggedInUser = loggedInResponse.user;
      const loggedInToken = loggedInResponse.token;

      onSubmitProps.resetForm();

      if (loggedInResponse) {
        dispatch(
          setLogin({
            user: loggedInUser,
            token: loggedInToken,
          })
        );
        hitApi.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${loggedInToken}`;
        hitApi.defaults.headers.common["Content-Type"] = "application/json";
        setShowModal(true); // Show the modal

        // Store the token and user in localStorage or sessionStorage based on rememberMe
        if (rememberMe) {
          localStorage.setItem("token", loggedInToken);
          localStorage.setItem(
            "user",
            JSON.stringify({
              _id: loggedInUser._id,
              username: loggedInUser.username,
            })
          );
        } else {
          sessionStorage.setItem("token", loggedInToken);
          sessionStorage.setItem(
            "user",
            JSON.stringify({
              _id: loggedInUser._id,
              username: loggedInUser.username,
            })
          );
        }

        let userId = loggedInUser._id;

        // Fetch liked news and store it in Redux
        const likedNewsResponse = await hitApi.get(`/users/like/${userId}`);

        dispatch(setLikedNews(likedNewsResponse.data.data.likedNews));
        dispatch(setTotalLikes(likedNewsResponse.data.data.totalLikes));
        dispatch(setTotalDislikes(likedNewsResponse.data.data.totalDislikes));

        setIsSuccess(true); // Show the success message

        setTimeout(() => {
          navigate(`/home`);
          setIsLoading(false); // Hide the loading component
          setIsSuccess(false); // Hide the success message
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      setIsError(true);
      console.error("Error during login:", error.message);
    } finally {
      setIsLoading(false); // Ensure loading state is correctly managed
      onSubmitProps.resetForm(); // Reset form here to ensure it's always called
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    setIsLoading(true); // Show the spinner
    await login(values, onSubmitProps);
    setIsLoading(false); // Hide the spinner
    // setIsSuccess(true); // Show the success message
  };

  return (
    <div className="bg-zinc-200 bg-opacity-25 h-full dark:bg-black dark:bg-opacity-25 w-full sm:px-6 md:px-8 lg:px-10 p-6 rounded-ee-lg rounded-ss-lg">
      <div className="flex flex-col w-full px-4 py-8 rounded-ee-lg rounded-ss-lg shadow-lg bg-zinc-100 dark:bg-zinc-900 sm:px-6 md:px-8 lg:px-10">
        <div className="mb-6 font-bold p-2 text-zinc-600 dark:text-white w-full text-center">
          <p className="text-xl">
            We kindly request you to{" "}
            <span className="text-parewacreamcanprimary-300">Log In</span> to
            your account,
          </p>
          <p className="text-xs text-center tracking-wider mt-4">
            which will enable you to fully utilize our features.
          </p>
        </div>
        <div className="p-6 shadow-lg border-b-2 border-zinc-300 dark:border-zinc-800">
          <LoginForm
            buttonName="Login"
            isLoading={isLoading}
            onSubmit={handleFormSubmit}
            isSuccess={isSuccess}
            setRememberMe={setRememberMe}
          />
          {isError ? (
            <span className="flex text-xs p-1 mt-2 w-full text-red-600 justify-center">
              Invalid Credentials. Please try again.
            </span>
          ) : (
            ""
          )}
          <SuccessfulModal open={showModal} onClose={() => setShowModal(false)}>
            <div className="bg-white p-4 rounded">
              <h2>Login successful!</h2>
              {isLoading ? <div>Redirectingggg....</div> : null}
            </div>
          </SuccessfulModal>
          <div className="flex justify-between mt-2 p-2 xxs:bg-white xxs:dark:bg-zinc-700 sm:dark:bg-transparent sm:bg-transparent">
            <div className="flex-col flex justify-center w-full px-1">
              <span className="text-xs text-left w-full text-black mb-1 dark:text-zinc-300">
                New here?{" "}
                <a
                  id="create_an_account_button"
                  href="/register"
                  className="text-blue-500 cursor-pointer hover:underline"
                >
                  Create an account
                </a>
              </span>
              <button
                id="sign_up_button"
                className="antialiased bg-zinc-200 hover:bg-opacity-60 dark:hover:bg-opacity-50 dark:bg-zinc-700 dark:text-black xxs:text-xs sm:text-sm rounded-sm p-2 w-full text-center"
                onClick={() => {
                  navigate(`/register`);
                }}
              >
                <span className="dark:text-parewacreamcanprimary-200">
                  Sign Up
                </span>{" "}
                Here
              </button>
            </div>
          </div>
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <button
            type="button"
            onClick={() => setShowLoginAlert(false)}
            title="Close Pop Up"
            className="rounded hover:opacity-50 px-4 py-2 text-xs font-medium text-black dark:text-white flex items-center gap-1"
          >
            Close <span className="flex items-start h-6">x</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
